<div class="pretty-check {{ identifier }} {{ modifier }}"
    (click)="selectValue()"
    (keyup.space)="selectValue()"
    (keyup.enter)="selectValue()"
    tabindex="0"
    >
    <input
        type="radio"
        [name]="name"
        [value]="value"
        [checked]="value === selectedGroupValue"
        (change)="selectValue()"
        [disabled]="isDisabled"
        [id]="value"
    />
    @if (label.length > 0) {
        <label class="label {{ isLabelHidden ? 'label--hidden' : '' }}" for="{{id}}">
            {{ label }}
            @if (isRequired) {
                <span class="label__asterisk">*</span>
            }
        </label>
    }
</div>
