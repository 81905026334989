import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRFooterComponent } from '@lsr/ui-components/lsr-footer';
import { LSRHeaderComponent } from '@lsr/ui-components/lsr-header';
import { LSRMobileHeaderComponent } from '@lsr/ui-components/lsr-mobile-header';
import { LSRSplashScreenComponent } from '@lsr/ui-components/lsr-splash-screen';
import { LSRToastComponent } from '@lsr/ui-components/lsr-toast';
import { combineLatest, filter, first } from 'rxjs';
import { routes } from '../app/app.routes';
import { AuthService } from '../services/auth/auth.service';
import { TranslationService } from '../services/translation/translationService';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        LSRFooterComponent,
        LSRHeaderComponent,
        LSRMobileHeaderComponent,
        LSRSplashScreenComponent,
        CommonModule,
        TranslocoModule,
        LSRToastComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'customer-portal';
    routes = routes;
    showSplash = true;

    constructor(
        private authService: AuthService,
        private router: Router,
        public translocoService: TranslocoService,
        private translationService: TranslationService
    ) {}

    ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            if (this.authService.authenticated && this.checkUrl()) {
                this.authService.refreshAccessToken().subscribe();
            }
        });

        this.appInitialized().subscribe(() => {
            this.showSplash = false;
        });

        this.setLanguage();
    }

    checkUrl = () => {
        if (window.location.href.indexOf('stadfesting-netfangs') > -1 || window.location.href.indexOf('login') > -1) {
            return false;
        } else {
            return true;
        }
    };

    setLanguage = () => {
        // Check if the language query parameter was defined
        const queryParams = new URLSearchParams(window.location.search);
        const queryLang = queryParams.get('lang');

        // Language default is is
        let selectedLang = 'is';
        if (queryLang) {
            // Check if the query param value is an available language
            const availableLangs = this.translocoService.getAvailableLangs();
            selectedLang = queryLang && availableLangs.some((l) => l == queryLang) ? queryLang : selectedLang;
        } else {
            // Check if the user has a stored language in localstorage
            const storedLang = this.translationService.getStoredLang();
            selectedLang = storedLang ? storedLang : selectedLang;
        }

        this.translocoService.setActiveLang(selectedLang);
    };

    // Returns an observable that returns a value once the application has initialized
    appInitialized = () => {
        // Check if angular is ready
        const appReady = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            first()
        );

        // Check if translations have been loaded
        const translationsLoaded = this.translationService.translationsLoaded.pipe(
            filter((x) => x == true),
            first()
        );

        return combineLatest([appReady, translationsLoaded]).pipe(first());
    };
}
