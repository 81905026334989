import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { LSRIconComponent } from '../lsr-icon/lsr-icon.component';

@Component({
    selector: 'lsr-select',
    standalone: true,
    imports: [FormsModule, LSRIconComponent, TranslocoPipe],
    templateUrl: `lsr-select.component.html`,
    styleUrl: `lsr-select.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-select' },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LSRSelectComponent,
            multi: true,
        },
    ],
})
export class LSRSelectComponent implements ControlValueAccessor {
    @Input() identifier: string = '';
    @Input() modifier: string = '';
    @Input() label: string = '';
    @Input() isRequired?: boolean;
    @Input() isLabelHidden?: boolean;
    @Input() isDisabled: boolean = false;
    @Input() icon?: string;
    @Input() iconColor?: string;
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() placeholder: string = '';
    @Input() errorMessage: string = '';
    @Input() options: SelectOption[] = [];
    @Input() clearable: boolean = false;

    selectedOption: string | number | null = '';
    placeholderValue: string = '__placeholder__';
    resetValue: string = '__reset__';

    @Output() valueChange = new EventEmitter<string | number | null>();

    constructor(private cdr: ChangeDetectorRef) {}

    onChange: ((value: string | number | null) => void) | null = null;
    onTouched: (() => void) | null = null;

    writeValue(value: string | number): void {
        this.selectedOption = value ?? this.placeholderValue;
    }

    registerOnChange(fn: (value: string | number | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onSelectChange(event: Event) {
        const value = (event.target as HTMLSelectElement).value;

        /* 
            Force the placeholder option to be selected again by first setting its value to an empty string.
            Otherwise the select will show the empty option display value when it is selected with the select already empty.
        */
        if (value === 'null') {
            this.selectedOption = this.resetValue;
            this.cdr.detectChanges();
        }

        this.selectedOption = value === 'null' ? this.placeholderValue : value;
        const publishedValue = value === 'null' ? null : value;

        if (this.onChange) {
            this.onChange(publishedValue);
        }
        this.valueChange.emit(publishedValue);
    }
}

export interface SelectOption {
    title: string;
    value: string | number | Date;
}
