import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, combineLatest, first, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private translationsLoadedSubject = new BehaviorSubject<boolean>(false);
    translationsLoaded: Observable<boolean> = this.translationsLoadedSubject.asObservable();

    constructor(
        private translocoService: TranslocoService,
        private config: PrimeNGConfig
    ) {
        const langs = this.translocoService.getAvailableLangs();
        const loadingLangs = langs.map((x) => this.translocoService.load(x as string).pipe(first()));
        // Checking if all available languages have been loaded
        combineLatest(loadingLangs).subscribe(() => {
            this.translationsLoadedSubject.next(true);

            this.translocoService.langChanges$.subscribe((res) => {
                // Translations have to be updated for PrimeNG components on transloco language changes
                this.config.setTranslation(JSON.parse(this.translocoService.translateObject('PrimeNG')));
                // Store the selected language to perserve on page refresh/new tab
                this.setStoredLang(res);
            });
        });
    }

    public showTranslationKeys = () => {
        const langs = this.translocoService.getAvailableLangs();
        for (const lang of langs) {
            const translation = this.translocoService.getTranslation(lang as string);
            for (const t in translation) {
                if (t === 'PrimeNG') {
                    continue;
                }
                translation[t] = t;
            }
            this.translocoService.setTranslation(translation, lang as string);
        }
    };

    public setStoredLang = (lang: string) => {
        localStorage.setItem('lsr-language', lang);
    };

    public getStoredLang = () => {
        return localStorage.getItem('lsr-language');
    };
}
