@if (targetDate && currentUser) {
    <div class="impersonation_bar">
        <div class="container">
            <span [innerHtml]="'mpibMessage' | transloco: { name: currentUser.name, ssn: currentUser.ssn } | sanitizeHtml"></span>
            <lsr-countdown [targetDate]="targetDate" (countdownComplete)="handleLogout()"></lsr-countdown>
        </div>
    </div>
}
<lsr-header [routes]="routes" (logout)="handleLogout()"></lsr-header>
<lsr-mobile-header [routes]="routes" (logout)="handleLogout()"></lsr-mobile-header>
<!-- <div class="page-cover"></div> -->
<main class="main" id="main">
    <div class="container">
        <!-- Content from path rendered in router-outlet  -->
        <router-outlet></router-outlet>
    </div>
</main>
<lsr-footer></lsr-footer>

<lsr-dialog-confirm
    headerTitle="{{ 'mpecdTitle' | transloco }}"
    [includeCancelButton]="false"
    [closeOnAction]="false"
    [confirmButtonDisabled]="!emailConfirmationForm.valid"
    [confirmButtonLoading]="emailConfirmLoading"
    [confirmButtonText]="
        (!currentUser?.email ? 'mpecdConfirmButtonTextNoEmail' : 'mpecdConfirmedButtonTextUnconfirmedEmail') | transloco
    "
>
    <section class="email-confirmation-section">
        @if (!currentUser?.email) {
            <p>{{ 'mpecdTextNoEmail' | transloco }}</p>
        } @else if (!currentUser?.emailVerified) {
            <p>
                {{ 'mpecdTextWithRegisteredEmail' | transloco }}
            </p>
        }
        <form [formGroup]="emailConfirmationForm">
            <lsr-input
                [isLabelHidden]="true"
                [errorMessage]="emailErrorMessage"
                name="email"
                placeholder="{{ 'mpecdEmailInputPlaceholder' | transloco }}"
                formControlName="email"
                (blur)="setContactValidationMessages()"
                [modifier]="
                    !emailConfirmationForm.controls.email.valid && !emailConfirmationForm.controls.email.pristine
                        ? 'input-group--error'
                        : ''
                "
            ></lsr-input>
        </form>
    </section>
</lsr-dialog-confirm>
