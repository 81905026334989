export const LoanStatus = {
    Unknown: { value: 0, translationKey: 'mpgLoanStatusUnknown' },
    Active: { value: 1, translationKey: 'mpgLoanStatusActive' },
    InChangeOfTerms: { value: 2, translationKey: 'mpgLoanStatusInChangeOfTerms' },
    TermsChanged: { value: 3, translationKey: 'mpgLoanStatusTermsChanged' },
    PaidUp: { value: 4, translationKey: 'mpgLoanStatusPaidUp' },
    WrittenOff: { value: 6, translationKey: 'mpgLoanStatusWrittenOff' },
};

export const getLoanStatus = (loanStatusNumber: number) => {
    switch (loanStatusNumber) {
        case LoanStatus.Active.value:
            return LoanStatus.Active;
        case LoanStatus.InChangeOfTerms.value:
            return LoanStatus.InChangeOfTerms;
        case LoanStatus.PaidUp.value:
            return LoanStatus.PaidUp;
        case LoanStatus.TermsChanged.value:
            return LoanStatus.TermsChanged;
        case LoanStatus.WrittenOff.value:
            return LoanStatus.WrittenOff;
        default:
            return LoanStatus.Unknown;
    }
};
