import { Location } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { checkBrowser } from '../../../../helpers/checkBrowser';
import { ApplicationSystems } from '../../../../helpers/constants/application-systems.constants';
import { BrowserConstants } from '../../../../helpers/constants/browser.constants';
import { ApplicationInfo } from '../../../../interfaces/support/applications/applicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-personal-pension-applications',
    templateUrl: 'personal-pension-applications.component.html',
    styleUrl: './personal-pension-applications.component.scss',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    host: { class: 'main__content' },
})
export class PersonalPensionApplicationsComponent implements AfterViewInit {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';
    public selectedApplication?: ApplicationInfo;
    public generalApplications: ApplicationInfo[] = [];
    public specifiedApplications: ApplicationInfo[] = [];
    BrowserConstants = BrowserConstants;
    ApplicationSystems = ApplicationSystems;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location,
        private translocoService: TranslocoService
    ) {
        this.initApplications();

        this.translocoService.langChanges$.subscribe(() => {
            this.initApplications();
        });
    }

    ngAfterViewInit(): void {
        if (this.dialog) {
            // Timeout is necessary to fix console error ExpressionChangedAfterItHasBeenCheckedError
            window.setTimeout(() => {
                this.checkUrl();
            });
        }
    }

    /**
     * Opens the selected personal pension application in a dialog.
     * Retrieves a token for authentication and sets up the redirect URL.
     * @param application The personal pension application to open
     */
    openApplication(application: ApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => {
            this.location.go(`/sereign/umsoknir/${pathSuffix}`);
        };

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        updateUrlPath(application.identifier);

        if (application.system === ApplicationSystems.Coredata) {
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
                .subscribe((token) => {
                    this.url = `${environment.coredataPortalUrl}?token=${token}`;
                    this.redirectUrl = application.url;
                    application.isLoading = false;
                    openDialogAndSubscribeClose();
                });
        } else {
            this.url = application.url;
            application.isLoading = false;
            openDialogAndSubscribeClose();
        }
    }

    /**
     * Checks the current route for specific application parameters and,
     * if present, opens the corresponding application.
     */
    checkUrl() {
        const first = this.route.snapshot.paramMap.get('identifier1');
        const second = this.route.snapshot.paramMap.get('identifier2');

        if (!first) {
            return;
        }

        let identifier = first;
        if (second) {
            identifier += `/${second}`;
        }

        const application =
            this.generalApplications.find((x) => x.identifier == identifier) ||
            this.specifiedApplications.find((x) => x.identifier == identifier);

        this.openApplication(application as ApplicationInfo);
    }

    /**
     * Initializes the available personal pension applications with their respective details.
     * Separates applications into general and specified categories.
     */
    initApplications() {
        this.generalApplications = [
            {
                titleTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsDescription',
                url: `${environment.coredataPortalUrl}/23/forms/46/`,
                identifier: '23/46',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionAmendmentOfExistingAgreementOnPersonalPensionSavingsTitle',
                descriptionTranslation:
                    'mpaPersonalPensionAmendmentOfExistingAgreementOnPersonalPensionSavingsDescription',
                url: `${environment.coredataPortalUrl}/23/forms/47/`,
                identifier: '23/47',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionPayoutOfPesonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionPayoutOfPesonalPensionSavingsDescription',
                url: `${environment.coredataPortalUrl}/23/forms/44/`,
                identifier: '23/44',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionSuspensionOfPayoutOfPesonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionSuspensionOfPayoutOfPesonalPensionSavingsDescription',
                url: `${environment.coredataPortalUrl}/23/forms/76/`,
                identifier: '23/76',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionDivisionOfPersonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionDivisionOfPersonalPensionSavingsDescription',
                url: `${environment.coredataPortalUrl}/23/forms/45/`,
                identifier: '23/45',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionTerminationOfPersonalPensionSavingsAgreementTitle',
                descriptionTranslation: 'mpaPersonalPensionTerminationOfPersonalPensionSavingsAgreementDescription',
                url: `${environment.coredataPortalUrl}/23/forms/86/`,
                identifier: '23/86',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
        ];

        if (this.translocoService.getActiveLang() === 'en') {
            this.generalApplications.unshift(
                ...[
                    {
                        titleTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsTitleEnglish',
                        descriptionTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsDescription',
                        url: 'https://fill.taktikal.is/s/f1f84cdf46f4',
                        identifier: 'sereign-en',
                        system: ApplicationSystems.Taktikal,
                        isLoading: false,
                    },
                ]
            );
        }

        this.specifiedApplications = [
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionApplicationTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionApplicationDescription',
                url: `${environment.coredataPortalUrl}/23/forms/82/`,
                identifier: '23/82',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionApplicationForPayoutTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionApplicationForPayoutDescription',
                url: `${environment.coredataPortalUrl}/23/forms/87/`,
                identifier: '23/87',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionTerminationTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionTerminationDescription',
                url: `${environment.coredataPortalUrl}/23/forms/85/`,
                identifier: '23/85',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
        ];
    }

    getPageSubtitle = () => {
        if (this.translocoService.getActiveLang() === 'en') {
            return 'mpaPensionApplicationsSubtitle';
        }
        return null;
    };

    checkBrowser(): string {
        return checkBrowser();
    }
}
