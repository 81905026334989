import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { ExposedPensionBenefitsLSRBRes } from '../interfaces/responses/exposed/exposedPensionBenefitsLSRBRes';

export const pensionBenefitsLSRBToCardData = (
    res: ExposedPensionBenefitsLSRBRes | null,
    age: number,
    localeService: TranslocoLocaleService
) => {
    if (!res) {
        return;
    }

    let amountString = '';
    let description = '';
    if (res.benefitsParked) {
        amountString = localeService.localizeNumber(res.currentAcquiredPensionBenefitsAmount, 'currency');
        description =
            age > 65
                ? 'mppAcquiredBenefitsLSRBCardSubtitleMonthlyCurrent'
                : 'mppAcquiredBenefitsLSRBCardSubtitleMonthly65';
    } else {
        amountString = localeService.localizeNumber(
            res.currentAcquiredPensionBenefitsPercentage,
            'percent',
            undefined,
            { minimumFractionDigits: 3, maximumFractionDigits: 3 }
        );
        description = 'mppAcquiredBenefitsLSRBCardSubtitleSalaryRatio';
    }

    return {
        amountString,
        description,
    } as { amountString: string; description: string };
};

/* 
    Return the latest pension payment cutoff date, the cutoff date is always two years before the current date (two year window decided by ABS)
    If no payment is received after the cutoff date then the latest pension payments overview cards should not be displayed
*/
export const getLatestPensionPaymentCutoffDate = () => {
    const cutoffDate = new Date();
    cutoffDate.setFullYear(cutoffDate.getFullYear() - 2);
    return cutoffDate;
};
