import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Message, MessageService as PrimeMessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(
        private authService: AuthService,
        private primeMessageService: PrimeMessageService,
        private translocoService: TranslocoService
    ) {}

    /**
     * Adds a message to be displayed as a toast
     * Wrapper for add method in PrimeNG's message service
     * @param message Message object to be displayed
     * @param alwaysShowDetail Determines if the detail in the message should always be shown regardless of wether the current user is being impersonated or not
     */
    public add = (message: Message, alwaysShowDetail: boolean = false) => {
        // Only show detailed error messages to impersonating employees
        if (!alwaysShowDetail && !this.authService.isImpersonating()) {
            message.detail = undefined;
        }

        this.primeMessageService.add(message);
    };

    /**
     * Handles an error response status object and adds a message to be displayed in a toast
     * @param status Status object returned from the LSR API
     */
    public handleErrorResponse = (res: HttpErrorResponse) => {
        // Cases where 403 errors occur:
        // - For impersonating employees these are always errors related to impersonation permissions (decided by UT that these should not be shown)
        // - For normal users these are always related to data permissions, for example the user receives a 403 error in case they cannot fetch current benefits due to currently receiving pension payments.
        // For all of these cases no toast error notificiation should be displayed to the user, the data is simply not shown on the relevant pages
        if (res.status == HttpStatusCode.Forbidden) {
            return;
        }

        // Default error in case the error response is not of the type we expect
        if (!res.error.errorItem) {
            this.add({
                severity: 'error',
                summary: this.translocoService.translate('mpgUnexpectedErrorMessage'),
            });
            return;
        }

        this.add({
            severity: 'error',
            summary: res.error.errorItem.friendlyMessage,
            detail: res.error.errorItem.message,
        });
    };
}
