import { Component, Input, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { SanitizeHtmlPipe } from '@lsr/pipes/sanitizeHTML.pipe';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRDialogBasicComponent } from '../lsr-dialog';

@Component({
    selector: 'lsr-footer',
    standalone: true,
    imports: [LSRIconComponent, LSRDialogBasicComponent, RouterLink, TranslocoPipe, SanitizeHtmlPipe],
    templateUrl: `lsr-footer.component.html`,
    styleUrl: `lsr-footer.component.scss`,
})
export class LSRFooterComponent {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;
    @Input() modifier?: string = '';
    @Input() companyName = 'Lífeyrissjóður starfsmanna ríkisins';
    @Input() companyAddress = 'Engjateigi 11, 105 Reykjavík';
    @Input() companyLocation = 'https://maps.app.goo.gl/YxtbNfa4myecChja7';
    @Input() companyPhone = '510 6100';
    @Input() openingHours: [string, string][] = [];

    constructor(public translocoService: TranslocoService) {
        this.translocoService.load('is').subscribe();

        // Add translations for opening hours
        this.translocoService.langChanges$.subscribe((res) => {
            this.translocoService.load(res).subscribe(() => {
                this.openingHours = JSON.parse(
                    this.translocoService.translate('mpfOpeningHours').replace(/'/g, '"').replace(/,\s*]/g, ']')
                );
            });
        });
    }

    openTermsOfService = () => {
        this.dialog.open();
    };
}
