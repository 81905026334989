import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet, Routes } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { LSRCountdownComponent } from '@lsr/ui-components/lsr-countdown';
import { LSRDialogConfirmComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRFooterComponent } from '@lsr/ui-components/lsr-footer';
import { LSRHeaderComponent } from '@lsr/ui-components/lsr-header';
import { LSRInputTextComponent } from '@lsr/ui-components/lsr-input/lsr-input-text';
import { LSRMobileHeaderComponent } from '@lsr/ui-components/lsr-mobile-header';
import { ValidateEmail } from '@lsr/utils/validators';
import { combineLatest, first } from 'rxjs';
import { UserRoles } from '../../../helpers/constants/user-role-constants';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { AuthService } from '../../../services/auth/auth.service';
import { MessageService } from '../../../services/message/message.service';
import { TranslationService } from '../../../services/translation/translationService';
import { UserService } from '../../../services/user/user.service';
import { SanitizeHtmlPipe } from '@lsr/pipes/sanitizeHTML.pipe';

@Component({
    selector: 'app-auth-layout',
    standalone: true,
    imports: [
        RouterOutlet,
        LSRFooterComponent,
        LSRHeaderComponent,
        LSRMobileHeaderComponent,
        LSRCountdownComponent,
        LSRDialogConfirmComponent,
        LSRInputTextComponent,
        ReactiveFormsModule,
        TranslocoPipe,
        SanitizeHtmlPipe
    ],
    templateUrl: './auth-layout.component.html',
    styleUrl: './auth-layout.component.scss',
    host: { class: 'layout layout--full-height' },
})
export class AuthLayoutComponent implements OnInit, AfterContentInit {
    @ViewChild(LSRDialogConfirmComponent) emailConfirmationDialog?: LSRDialogConfirmComponent;
    routes: Routes = [];
    targetDate: Date | null = null;
    currentUser: ExposedMeRes | null = null;
    initialEmailConfirmationChecked: boolean = false;
    emailErrorMessage: string = '';
    emailConfirmLoading: boolean = false;

    emailConfirmationForm = {} as FormGroup<{
        email: FormControl<string | null>;
    }>;

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private fb: FormBuilder,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private translationService: TranslationService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.emailConfirmationForm = this.fb.group({
            email: ['', [Validators.required, ValidateEmail]],
        });

        this.emailConfirmationForm.controls.email.valueChanges.subscribe(() => {
            if (this.emailErrorMessage) {
                this.emailErrorMessage = '';
            }
        });

        this.route.queryParams.subscribe((params) => {
            // Custom param to display translation keys instead of the translation itself
            // Only accessible to employees with the translations superuser access role
            const showTranslationKeys = params['showTranslationKeys'];
            if (showTranslationKeys) {
                this.authService.checkHasRole(UserRoles.TranslationsSuperuser).subscribe({
                    next: (hasRole) => {
                        if (hasRole) {
                            this.translationService.showTranslationKeys();
                        }
                    },
                });
            }
        });
        if (this.route.queryParams)
            combineLatest([this.authService.accessTokenExpiryDate, this.userService.currentUser]).subscribe(
                ([expireDate, user]) => {
                    this.currentUser = user;
                    this.emailConfirmationForm.patchValue(
                        {
                            email: this.currentUser?.email,
                        },
                        { emitEvent: false, onlySelf: false }
                    );

                    if (!this.initialEmailConfirmationChecked && this.currentUser != null) {
                        if (!this.currentUser.emailVerified) {
                            this.openEmailConfirmationDialog();
                        }
                        this.initialEmailConfirmationChecked = true;
                    }

                    if (this.authService.isImpersonating()) {
                        this.targetDate = expireDate;
                    }
                }
            );
        this.authService.accessTokenExpiryDate.subscribe((date) => {
            if (this.authService.isImpersonating()) {
                this.targetDate = date;
            }
        });
    }

    ngAfterContentInit(): void {
        const routes = (this.router.config.find((route) => route.data?.['domain'] === 'menuItems')?.children ||
            []) as Routes;

        // Take a deep copy of routes to avoid mutating the original array.
        this.routes = JSON.parse(JSON.stringify(routes));
        this.addPathParentPrefixToChildren();
    }

    // Ensures that parent prefixes are added to routes where applicable,
    // ensuring proper functionality of sub-paths in the mobile header.
    private addPathParentPrefixToChildren(): void {
        if (this.routes) {
            this.routes.forEach((route) => {
                if (route.children && route.path) {
                    route.children.forEach((child) => {
                        // Ensure child path doesn't already start with parent path
                        if (child.path && route.path && !child.path.startsWith(route.path + '/')) {
                            child.path = route.path + '/' + child.path;
                        }
                    });
                }
            });
        }
    }

    private openEmailConfirmationDialog = () => {
        if (
            this.emailConfirmationDialog &&
            this.currentUser != null &&
            (this.currentUser?.email == null || !this.currentUser.emailVerified)
        ) {
            this.emailConfirmationDialog.open();
            this.emailConfirmationDialog.dialogClosed.pipe(first()).subscribe({
                next: (confirmed) => {
                    if (!confirmed || !this.emailConfirmationForm.valid) {
                        return;
                    }
                    this.emailConfirmLoading = true;
                    const formEmailValue = this.emailConfirmationForm.value?.email?.trim() ?? null;
                    if (formEmailValue && formEmailValue == this.currentUser?.email) {
                        this.userService.sendConfirmationEmail().subscribe({
                            next: () => {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: this.translocoService.translate(
                                        'mpecdConfirmationEmailSentSuccessMessage'
                                    ),
                                });
                                this.emailConfirmationDialog?.close();
                            },
                            error: () => {
                                this.messageService.add({
                                    severity: 'error',
                                    summary: this.translocoService.translate('mpecdConfirmationEmailSentFailedMessage'),
                                });
                                this.emailConfirmLoading = false;
                            },
                            complete: () => {
                                this.emailConfirmLoading = false;
                            },
                        });
                    } else {
                        this.userService
                            .updateUserPublic({
                                kennitala: this.currentUser!.ssn,
                                netfang: this.emailConfirmationForm.value.email!,
                            })
                            .subscribe({
                                next: () => {
                                    this.messageService.add({
                                        severity: 'success',
                                        summary: this.translocoService.translate('mpecdEmailUpdateSuccessMessage'),
                                    });
                                    this.emailConfirmationDialog?.close();
                                },
                                error: () => {
                                    this.messageService.add({
                                        severity: 'error',
                                        summary: this.translocoService.translate('mpecdEmailUpdateFailedMessage'),
                                    });
                                    this.emailConfirmLoading = false;
                                },
                                complete: () => {
                                    this.emailConfirmLoading = false;
                                },
                            });
                    }
                },
            });
        }
    };

    setContactValidationMessages = (): void => {
        const { email: emailControl } = this.emailConfirmationForm.controls;
        if (emailControl.errors) {
            if (emailControl.errors?.['required']) {
                this.emailErrorMessage = this.translocoService.translate('mpecdEmailInputErrorMessageEmpty');
            } else if (emailControl.errors?.['validEmail']) {
                this.emailErrorMessage = this.translocoService.translate('mpecdEmailInputErrorMessageInvalid');
            }
        } else {
            this.emailErrorMessage = '';
        }
    };

    handleLogout = () => {
        this.authService.logout();
        this.router.navigate(['login']);
    };
}
