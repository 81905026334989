@if (loading) {
    <div class="loader-wrapper">
        <lsr-loader loadingText="{{ 'mpoPageLoadingText' | transloco }}"></lsr-loader>
    </div>
} @else {
    <section class="grid grid--4-columns hide-empty">
        @if (pensionBenefitsLSRA) {
            <lsr-amount-card
                title="{{ 'mpoAcquiredBenefitsLSRACardTitle' | transloco }}"
                amountString="{{ pensionBenefitsLSRA.currentAcquiredPensionBenefits | translocoCurrency }}"
                subtitle="{{
                    'mppAcquiredBenefitsLSRACardSubtitle'
                        | transloco
                            : {
                                  age:
                                      (pensionBenefitsLSRA.retirementAge
                                      | translocoDecimal: { maximumFractionDigits: 0 }),
                              }
                }}"
                linkTitle="{{ 'mpoAcquiredBenefitsLSRACardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (pensionBenefitsLSRB) {
            <lsr-amount-card
                title="{{ 'mpoAcquiredBenefitsLSRBCardTitle' | transloco }}"
                amountString="{{ pensionBenefitsLSRBCardData?.amountString }}"
                subtitle="{{ pensionBenefitsLSRBCardData?.description | transloco }}"
                linkTitle="{{ 'mpoAcquiredBenefitsLSRBCardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (traditionalPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoPersonalPensionCardTitle' | transloco }}"
                amountString="{{ traditionalPersonalPensionCardData.amountString }}"
                subtitle="{{ traditionalPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoPersonalPensionCardLinkText' | transloco }}"
                linkHref="/sereign/hefdbundin-sereign"
            ></lsr-amount-card>
        }
        @if (specifiedPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoSpecifiedPersonalPensionCardTitle' | transloco }}"
                amountString="{{ specifiedPersonalPensionCardData.amountString }}"
                subtitle="{{ specifiedPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoSpecifiedPersonalPensionCardLinkText' | transloco }}"
                linkHref="/sereign/tilgreind-sereign-lsr"
            ></lsr-amount-card>
        }
        @if (latestPaymentsCommonPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoLatestPaymentsCommonPensionCardTitle' | transloco }}"
                amountString="{{ latestPaymentsCommonPensionCardData.amountString }}"
                subtitle="{{ latestPaymentsCommonPensionCardData.description }}"
                linkTitle="{{ 'mpoLatestPaymentsCardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (latestPaymentsPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoLatestPaymentsPersonalPensionCardTitle' | transloco }}"
                amountString="{{ latestPaymentsPersonalPensionCardData.amountString }}"
                subtitle="{{ latestPaymentsPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoLatestPaymentsCardLinkText' | transloco }}"
                linkHref="/sereign/hefdbundin-sereign"
            ></lsr-amount-card>
        }
        @if (loansCardData) {
            <lsr-amount-card
                title="{{ 'mpoLoansCardTitle' | transloco }}"
                amountString="{{ loansCardData.amountString }}"
                subtitle="{{ loansCardData.description }}"
                linkTitle="{{ 'mpoLoanCardLinkText' | transloco }}"
                linkHref="/lan"
            ></lsr-amount-card>
        }
    </section>
    <section id="common-pension" class="info-section">
        <lsr-title text="{{ 'mpoSectionPensionTitle' | transloco }}" size="medium"></lsr-title>
        @if (pensionContributionsTableData || pensionPaymentsTableData) {
            <div
                class="grid"
                [ngClass]="
                    pensionContributionsTableData && pensionPaymentsTableData ? 'grid--2-columns' : 'grid--1-column'
                "
            >
                @if (pensionContributionsTableData) {
                    <lsr-table-preview
                        title="{{ 'mpoContributionsTableTitle' | transloco }}"
                        [data]="pensionContributionsTableData"
                        linkTitle="{{ 'mpoContributionsTableButtonText' | transloco }}"
                        linkHref="/lifeyrir"
                    ></lsr-table-preview>
                }
                @if (pensionPaymentsTableData) {
                    <lsr-table-preview
                        title="{{ 'mpoPaymentsTableTitle' | transloco }}"
                        [data]="pensionPaymentsTableData"
                        linkTitle="{{ 'mpoPaymentsTableButtonText' | transloco }}"
                        linkHref="/lifeyrir"
                    ></lsr-table-preview>
                }
            </div>
        } @else {
            <div class="no-data">
                <p class="body2 no-data--text">
                    {{ 'mpoSectionPensionNoDataText' | transloco }}
                </p>
                <div class="no-data__buttons">
                    <lsr-button
                        modifier="button--secondary button--full"
                        text="{{ 'mpoSectionPensionNoDataFurtherInfoButtonText' | transloco }}"
                        buttonType="link"
                        href="https://lsr.is/sereign/sereign/yfirlit/"
                    >
                    </lsr-button>
                    <lsr-button
                        modifier="button--secondary button--full"
                        text="{{ 'mpoSectionPensionNoDataOtherFundsButtonText' | transloco }}"
                        buttonType="link"
                        routerLink="/lifeyrir"
                    ></lsr-button>
                </div>
            </div>
        }
    </section>
    <section id="personal-pension" class="info-section">
        <lsr-title text="{{ 'mpoSectionPersonalPensionTitle' | transloco }}" size="medium"></lsr-title>
        @if (personalPensionContributionsTableData || personalPensionPaymentsTableData) {
            <div
                class="grid"
                [ngClass]="
                    personalPensionContributionsTableData && personalPensionPaymentsTableData
                        ? 'grid--2-columns'
                        : 'grid--1-column'
                "
            >
                @if (personalPensionContributionsTableData) {
                    <lsr-table-preview
                        title="{{ 'mpoPersonalPensionContributionsTableTitle' | transloco }}"
                        [data]="personalPensionContributionsTableData"
                        linkTitle="{{ 'mpoPersonalPensionContributionsTableButtonText' | transloco }}"
                        linkHref="/sereign"
                    ></lsr-table-preview>
                }
                @if (personalPensionPaymentsTableData) {
                    <lsr-table-preview
                        title="{{ 'mpoPersonalPensionPaymentsTableTitle' | transloco }}"
                        [data]="personalPensionPaymentsTableData"
                        linkTitle="{{ 'mpoPersonalPensionPaymentsTableButtonText' | transloco }}"
                        linkHref="/sereign"
                    ></lsr-table-preview>
                }
            </div>
        } @else {
            <div class="no-data">
                <p class="body2 no-data--text">
                    {{ 'mpoSectionPersonalPensionNoDataText' | transloco }}
                </p>
                <div class="grid grid--2-columns">
                    <lsr-action-card
                        title="{{ 'mpoSectionPersonalPensionNoDataTraditionalCardTitle' | transloco }}"
                        text="{{ 'mpoSectionPersonalPensionNoDataTraditionalCardText' | transloco }}"
                    >
                        <div class="card-buttons--wrapper">
                            <a
                                class="button button--secondary"
                                href="https://lsr.is/sereign/sereign/yfirlit/"
                                target="_blank"
                                >{{
                                    'mpoSectionPersonalPensionNoDataTraditionalCardFurtherInfoButtonText' | transloco
                                }}</a
                            >
                            <lsr-button
                                modifier="button--secondary button--full"
                                text="{{
                                    'mpoSectionPersonalPensionNoDataTraditionalApplicationButtonText' | transloco
                                }}"
                                buttonType="link"
                                routerLink="/sereign/umsoknir/23/46"
                            ></lsr-button>
                        </div>
                    </lsr-action-card>
                    <lsr-action-card
                        title="{{ 'mpoSectionPersonalPensionNoDataSpecifiedCardTitle' | transloco }}"
                        text="{{ 'mpoSectionPersonalPensionNoDataSpecifiedCardText' | transloco }}"
                    >
                        <div class="card-buttons--wrapper">
                            <a
                                class="button button--secondary"
                                href="https://lsr.is/sereign/tilgreind-sereign/yfirlit/"
                                target="_blank"
                                >{{
                                    'mpoSectionPersonalPensionNoDataSpecifiedCardFurtherInfoButtonText' | transloco
                                }}</a
                            >
                            <lsr-button
                                modifier="button--secondary button--full"
                                text="{{ 'mpoSectionPersonalPensionNoDataSpecifiedApplicationButtonText' | transloco }}"
                                buttonType="link"
                                routerLink="/sereign/umsoknir/23/82"
                            ></lsr-button>
                        </div>
                    </lsr-action-card>
                </div>
            </div>
        }
    </section>
    <section id="loans" class="info-section grid--1-column">
        <lsr-title text="{{ 'mpoSectionLoansTitle' | transloco }}" size="medium"></lsr-title>
        @if (loansTableData) {
            <div class="grid grid--1-column">
                <lsr-table-preview
                    title="{{ 'mpoLoansTableTitle' | transloco }}"
                    [data]="loansTableData"
                    linkTitle="{{ 'mpoLoansTableButtonText' | transloco }}"
                    linkHref="/lan"
                    [expandedFirstColumn]="false"
                ></lsr-table-preview>
            </div>
        } @else {
            <div class="no-data">
                <div
                    class="body2 no-data--text"
                    [innerHtml]="'mpoSectionLoansNoDataText' | transloco | sanitizeHtml"
                ></div>
                <div class="no-data__buttons">
                    <a class="button button--secondary" href="https://lsr.is/lan/yfirlit/" target="_blank">{{
                        'mpoSectionLoansNoDataFurtherInfoButtonText' | transloco
                    }}</a>
                    <lsr-button
                        modifier="button--secondary button--full"
                        text="{{ 'mpoSectionLoansNoDataLoanCalculatorButtonText' | transloco }}"
                        buttonType="link"
                        routerLink="/lan/lanareiknivel"
                    ></lsr-button>
                    <lsr-button
                        modifier="button--secondary button--full"
                        text="{{ 'mpoSectionLoansNoDataLoanEligibilityButtonText' | transloco }}"
                        buttonType="link"
                        routerLink="/lan/lansrettur"
                    ></lsr-button>
                </div>
            </div>
        }
    </section>
}
