import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LSRIconComponent } from '../lsr-icon/lsr-icon.component';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-splash-screen',
    standalone: true,
    imports: [LSRIconComponent, LSRLoaderComponent],
    templateUrl: `lsr-splash-screen.component.html`,
    styleUrl: `lsr-splash-screen.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-splash-screen' },
})
export class LSRSplashScreenComponent {
    public loaded: boolean = false;
    private _loading: boolean = true;
    @Input()
    get loading(): boolean {
        return this._loading;
    }
    set loading(value: boolean) {
        this.handleLoadingChange(value);
    }

    handleLoadingChange = (newValue: boolean) => {
        this.loaded = !newValue;

        if (!newValue) {
            // This timeout is necessary for the splash screen fadeout transition
            setTimeout(() => {
                this.loading = false;
            }, 200);
        } else {
            this.loading = false;
        }
    };
}
