import { formatDate, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TranslocoCurrencyPipe, TranslocoDecimalPipe, TranslocoLocaleService } from '@jsverse/transloco-locale';
import { MonthIdentifiers } from '@lsr/constants/monthIdentifiers.constants';
import { SanitizeHtmlPipe } from '@lsr/pipes/sanitizeHTML.pipe';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRAmountCardComponent } from '@lsr/ui-components/lsr-amount-card';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRNotificationComponent } from '@lsr/ui-components/lsr-notification';
import { TableData, TableRowData } from '@lsr/ui-components/lsr-table/lsr-table-interfaces';
import { LSRTablePreviewComponent } from '@lsr/ui-components/lsr-table/lsr-table-preview';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { getLoanStatus } from '../../../helpers/constants/loan-status.constants';
import { PensionAcquisitionTypes } from '../../../helpers/constants/pension-acquisition-types.constants';
import { PensionFunds } from '../../../helpers/constants/pension-funds.constants';
import { PersonalPensionTypes } from '../../../helpers/constants/personal-pension-types.constants';
import { getLatestPensionPaymentCutoffDate, pensionBenefitsLSRBToCardData } from '../../../helpers/pensionHelpers';
import { ExposedKriaPaidContributionsYearGroupRes } from '../../../interfaces/responses/exposed/exposedKriaPaidContributionsYearGroupRes';
import { ExposedKriaPensionPaymentsYearGroup } from '../../../interfaces/responses/exposed/exposedKriaPensionPaymentsYearGroup';
import { ExposedListKriaPensionPaymentsPersonalPension } from '../../../interfaces/responses/exposed/exposedListKriaPensionPaymentsPersonalPensionRes';
import { ExposedListKriaPensionPaymentsRes } from '../../../interfaces/responses/exposed/exposedListKriaPensionPaymentsRes';
import { ExposedListLibraLoanInfoRes } from '../../../interfaces/responses/exposed/exposedListLibraLoanInfoRes';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { ExposedPensionBenefitsLSRARes } from '../../../interfaces/responses/exposed/exposedPensionBenefitsLSRARes';
import { ExposedPensionBenefitsLSRBRes } from '../../../interfaces/responses/exposed/exposedPensionBenefitsLSRBRes';
import { ExposedPersonalPensionSavingsRes } from '../../../interfaces/responses/exposed/exposedPersonalPensionSavingsRes';
import { ContributionsService } from '../../../services/contributions/contributions.service';
import { LoansService } from '../../../services/loans/loans.service';
import { PensionService } from '../../../services/pension/pension.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-overview',
    standalone: true,
    imports: [
        LSRAmountCardComponent,
        LSRNotificationComponent,
        LSRTablePreviewComponent,
        LSRTitleComponent,
        LSRIconComponent,
        LSRLoaderComponent,
        TranslocoPipe,
        TranslocoCurrencyPipe,
        TranslocoDecimalPipe,
        LSRButtonComponent,
        LSRActionCardComponent,
        NgClass,
        SanitizeHtmlPipe,
    ],
    templateUrl: 'overview.component.html',
    styleUrl: './overview.component.scss',
    host: { class: 'main__content' },
})
export class OverviewComponent implements OnInit {
    userDetails?: ExposedMeRes;
    loading: boolean = true;
    currentMonth: string = 'september';
    lastPersonalPensionPayment: string = '14.08.2023';

    pensionBenefitsLSRA?: ExposedPensionBenefitsLSRARes;
    pensionBenefitsLSRB?: ExposedPensionBenefitsLSRBRes;
    pensionBenefitsLSRBCardData?: { amountString: string; description: string };

    latestPaymentsCommonPensionCardData?: { amountString: string; description: string };
    latestPaymentsPersonalPensionCardData?: { amountString: string; description: string };

    traditionalPersonalPensionCardData?: { amountString: string; description: string };
    specifiedPersonalPensionCardData?: { amountString: string; description: string };

    pensionContributionsTableData?: TableData;
    pensionPaymentsTableData?: TableData;

    personalPensionContributionsTableData?: TableData;
    personalPensionPaymentsTableData?: TableData;

    loansTableData?: TableData;
    loansCardData?: { amountString: string; description: string };

    // Define pension acquisition types to use in the component template
    PensionAcquisitionTypes = PensionAcquisitionTypes;

    constructor(
        private userService: UserService,
        private pensionService: PensionService,
        private contributionService: ContributionsService,
        private loansService: LoansService,
        private translocoService: TranslocoService,
        private localeService: TranslocoLocaleService
    ) {}

    ngOnInit(): void {
        this.userService.currentUser
            .pipe(
                filter((x) => x != null),
                take(1)
            )
            .subscribe((user) => {
                this.userDetails = user;
                this.initData();
            });

        this.translocoService.langChanges$.subscribe(() => {
            setTimeout(() => {
                this.initData();
            });
        });
    }

    initData = () => {
        if (!this.userDetails) {
            return;
        }
        this.loading = true;
        const requests = {} as {
            pensionBenefitsLSRA: Observable<ExposedPensionBenefitsLSRARes | null>;
            contributionsLSRA: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
            pensionPaymentsLSRA: Observable<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]>;

            pensionBenefitsLSRB: Observable<ExposedPensionBenefitsLSRBRes | null>;
            contributionsLSRB: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
            pensionPaymentsLSRB: Observable<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]>;

            savings: Observable<ExposedPersonalPensionSavingsRes>;
            traditionalPersonalPensionPayments: Observable<
                ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
            >;
            specifiedPersonalPensionPayments: Observable<
                ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
            >;
            traditionalPersonalPensionContributions: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
            specifiedPersonalPensionContributions: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;

            loans: Observable<ExposedListLibraLoanInfoRes[]>;
        };

        if (this.userDetails.funds.memberOfLSRA) {
            requests.pensionBenefitsLSRA = this.pensionService.getPensionBenefitsLSRA();
            requests.contributionsLSRA = this.contributionService.contributionsLSRA();
            requests.pensionPaymentsLSRA = this.pensionService.getPensionPayments(PensionFunds.LSRA);
        }

        if (this.userDetails.funds.memberOfLSRB) {
            requests.pensionBenefitsLSRB = this.pensionService.getPensionBenefitsLSRB();
            requests.contributionsLSRB = this.contributionService.contributionsLSRB();
            requests.pensionPaymentsLSRB = this.pensionService.getPensionPayments(PensionFunds.LSRB);
        }

        if (
            this.userDetails.funds.memberOfPersonalPensionSavings ||
            this.userDetails.funds.memberOfSpecifiedPersonalPensionSavings
        ) {
            requests.savings = this.pensionService.getPersonalPensionSavings();

            if (this.userDetails.funds.memberOfPersonalPensionSavings) {
                requests.traditionalPersonalPensionPayments = this.pensionService.getPersonalPensionPayments(
                    PersonalPensionTypes.Regular
                );
                requests.traditionalPersonalPensionContributions = this.contributionService.contributionsLSRSER();
            }

            if (this.userDetails.funds.memberOfSpecifiedPersonalPensionSavings) {
                requests.specifiedPersonalPensionPayments = this.pensionService.getPersonalPensionPayments(
                    PersonalPensionTypes.Specified
                );
                requests.specifiedPersonalPensionContributions = this.contributionService.contributionsLSRTS();
            }
        }

        requests.loans = this.loansService.loans();

        forkJoin(requests)
            .subscribe((res) => {
                this.processPensionBenefitsLSRA(res.pensionBenefitsLSRA);
                this.processPensionBenefitsLSRB(res.pensionBenefitsLSRB);
                this.processPensionContributions(res.contributionsLSRA, res.contributionsLSRB);
                this.processPensionPayments(res.pensionPaymentsLSRA, res.pensionPaymentsLSRB);

                this.processPersonalPensionSavings(
                    res.savings,
                    res.traditionalPersonalPensionContributions,
                    res.specifiedPersonalPensionContributions
                );
                this.processPersonalPensionContributions(
                    res.traditionalPersonalPensionContributions,
                    res.specifiedPersonalPensionContributions
                );
                this.processPersonalPensionPayments(
                    res.traditionalPersonalPensionPayments,
                    res.specifiedPersonalPensionPayments
                );

                this.processLatestCommonPensionPayments(res.pensionPaymentsLSRA, res.pensionPaymentsLSRB);
                this.processLatestPersonalPensionPayments(
                    res.traditionalPersonalPensionPayments,
                    res.specifiedPersonalPensionPayments
                );

                this.processLoans(res.loans);
            })
            .add(() => {
                this.loading = false;
            });
    };

    processPensionBenefitsLSRA = (res: ExposedPensionBenefitsLSRARes | null) => {
        if (!res) {
            return;
        }

        this.pensionBenefitsLSRA = res;
    };

    processPensionBenefitsLSRB = (res: ExposedPensionBenefitsLSRBRes | null) => {
        if (!res) {
            return;
        }

        this.pensionBenefitsLSRB = res;
        this.pensionBenefitsLSRBCardData = pensionBenefitsLSRBToCardData(
            res,
            this.userDetails!.age,
            this.localeService
        );
    };

    processPersonalPensionSavings = (
        res: ExposedPersonalPensionSavingsRes,
        traditionalContributions: ExposedKriaPaidContributionsYearGroupRes[],
        specifiedContributions: ExposedKriaPaidContributionsYearGroupRes[]
    ) => {
        if (!res || (!res.personalPension && !res.specifiedPersonalPension)) {
            return;
        }

        if (res.personalPension) {
            let description = '';
            if (traditionalContributions[0]?.contributions[0]) {
                description = this.translocoService.translate('mpoTraditionalPersonalPensionCardSubtitleDate', {
                    date: formatDate(traditionalContributions[0].contributions[0].paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                });
            } else {
                description = this.translocoService.translate('mpoTraditionalPersonalPensionCardSubtitleDefault');
            }

            this.traditionalPersonalPensionCardData = {
                amountString: this.localeService.localizeNumber(res.personalPension.balance, 'currency'),
                description,
            };
        }

        if (res.specifiedPersonalPension) {
            let description = '';
            if (specifiedContributions[0]?.contributions[0]) {
                description = this.translocoService.translate('mpoSpecifiedPersonalPensionCardSubtitleDate', {
                    date: formatDate(specifiedContributions[0].contributions[0].paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                });
            } else {
                description = this.translocoService.translate('mpoSpecifiedPersonalPensionCardSubtitleDefault');
            }

            this.specifiedPersonalPensionCardData = {
                amountString: this.localeService.localizeNumber(res.specifiedPersonalPension.balance, 'currency'),
                description,
            };
        }
    };

    processPensionContributions = (
        contributionsLSRA: ExposedKriaPaidContributionsYearGroupRes[],
        contributionsLSRB: ExposedKriaPaidContributionsYearGroupRes[]
    ) => {
        if (
            (!contributionsLSRA || contributionsLSRA.length == 0) &&
            (!contributionsLSRB || contributionsLSRB.length == 0)
        ) {
            return;
        }

        // Get latest contributions
        const latestContributions = (contributionsLSRA ?? [])
            .concat(contributionsLSRB ?? [])
            .flatMap((cyg) => cyg.contributions)
            .sort((a, b) => new Date(b.paymentDate!).getTime() - new Date(a.paymentDate!).getTime())
            .slice(0, 5);

        this.pensionContributionsTableData = {
            headerTitles: [
                'mpoTableHeaderEmployer',
                'mpoTableHeaderDate',
                'mpoTableHeaderFund',
                'mpoTableHeaderAmount',
            ],
            rows: latestContributions.map((c) => {
                return {
                    rowTitles: [
                        c.employerName,
                        formatDate(c.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                        c.pensionFund,
                        this.localeService.localizeNumber(c.total!, 'currency'),
                    ],
                } as TableRowData;
            }),
        };
    };

    processPersonalPensionContributions = (
        traditionalContributions: ExposedKriaPaidContributionsYearGroupRes[],
        specifiedContributions: ExposedKriaPaidContributionsYearGroupRes[]
    ) => {
        if (
            (!traditionalContributions || traditionalContributions.length == 0) &&
            (!specifiedContributions || specifiedContributions.length == 0)
        ) {
            return;
        }

        // Get latest contributions
        const latestContributions = (traditionalContributions ?? [])
            .concat(specifiedContributions ?? [])
            .flatMap((cyg) => cyg.contributions)
            .sort((a, b) => new Date(b.paymentDate!).getTime() - new Date(a.paymentDate!).getTime())
            .slice(0, 5);

        this.personalPensionContributionsTableData = {
            headerTitles: [
                'mpoTableHeaderEmployer',
                'mpoTableHeaderDate',
                'mpoTableHeaderFund',
                'mpoTableHeaderAmount',
            ],
            rows: latestContributions.map((c) => {
                return {
                    rowTitles: [
                        c.employerName,
                        formatDate(c.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                        c.pensionFund ?? c.investmentPlanName,
                        this.localeService.localizeNumber(c.total!, 'currency'),
                    ],
                } as TableRowData;
            }),
        };
    };

    processPensionPayments = (
        paymentsLSRA: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[],
        paymentsLSRB: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]
    ) => {
        if ((!paymentsLSRA || paymentsLSRA.length == 0) && (!paymentsLSRB || paymentsLSRB.length == 0)) {
            return;
        }

        // Get newest contributions
        const latestPayments = (paymentsLSRA ?? [])
            .concat(paymentsLSRB ?? [])
            .flatMap((pyg) => pyg.payments)
            .sort((a, b) => new Date(b.paymentDate!).getTime() - new Date(a.paymentDate!).getTime())
            .slice(0, 5);

        this.pensionPaymentsTableData = {
            headerTitles: [
                'mpoTableHeaderPaymentType',
                'mpoTableHeaderDate',
                'mpoTableHeaderFund',
                'mpoTableHeaderAmount',
            ],
            rows: latestPayments.map((p) => {
                return {
                    rowTitles: [
                        p.pensionType,
                        formatDate(p.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                        p.fund,
                        this.localeService.localizeNumber(p.paymentWithTax!, 'currency'),
                    ],
                } as TableRowData;
            }),
        };
    };

    processPersonalPensionPayments = (
        traditionalPayments: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[],
        specifiedPayments: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
    ) => {
        if (
            (!traditionalPayments || traditionalPayments.length == 0) &&
            (!specifiedPayments || specifiedPayments.length == 0)
        ) {
            return;
        }

        // Get newest contributions
        const latestPayments = (traditionalPayments ?? [])
            .concat(specifiedPayments ?? [])
            .flatMap((pyg) => pyg.payments)
            .sort((a, b) => new Date(b.paymentDate!).getTime() - new Date(a.paymentDate!).getTime())
            .slice(0, 5);

        this.personalPensionPaymentsTableData = {
            headerTitles: [
                'mpoTableHeaderPaymentType',
                'mpoTableHeaderDate',
                'mpoTableHeaderFund',
                'mpoTableHeaderAmount',
            ],
            rows: latestPayments.map((p) => {
                return {
                    rowTitles: [
                        p.pensionType,
                        formatDate(p.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                        p.fund,
                        this.localeService.localizeNumber(p.payment!, 'currency'),
                    ],
                } as TableRowData;
            }),
        };
    };

    processLatestCommonPensionPayments = (
        paymentsLSRA: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[],
        paymentsLSRB: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]
    ) => {
        const simplifiedPaymentsLSRA = (paymentsLSRA ?? [])
            .flatMap((pyg) => pyg.payments ?? [])
            .map((p) => {
                return { date: p.paymentDate, amount: p.paymentWithTax };
            });

        const simplifiedPaymentsLSRB = (paymentsLSRB ?? [])
            .flatMap((pyg) => pyg.payments ?? [])
            .map((p) => {
                return { date: p.paymentDate, amount: p.paymentWithTax };
            });

        const allPayments = [...simplifiedPaymentsLSRA, ...simplifiedPaymentsLSRB];

        const sum = this.sumLatestPaymentMonthPayments(allPayments);
        if (!sum) {
            return;
        }

        const { total, monthYearString } = sum;

        const description = this.translocoService.translate('mpoLatestPaymentsCommonPensionCardSubtitle', {
            month: monthYearString,
        });

        this.latestPaymentsCommonPensionCardData = {
            amountString: this.localeService.localizeNumber(total, 'currency'),
            description,
        };
    };

    processLatestPersonalPensionPayments = (
        paymentsLSRSER: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[],
        paymentsLSRTS: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
    ) => {
        const simplifiedPaymentsLSRSER = (paymentsLSRSER ?? [])
            .flatMap((pyg) => pyg.payments ?? [])
            .map((p) => {
                return { date: p.paymentDate, amount: p.payment };
            });

        const simplifiedPaymentsLSRTS = (paymentsLSRTS ?? [])
            .flatMap((pyg) => pyg.payments ?? [])
            .map((p) => {
                return { date: p.paymentDate, amount: p.payment };
            });

        const allPayments = [...simplifiedPaymentsLSRSER, ...simplifiedPaymentsLSRTS];

        const sum = this.sumLatestPaymentMonthPayments(allPayments);
        if (!sum) {
            return;
        }

        const { total, monthYearString } = sum;

        const description = this.translocoService.translate('mpoLatestPaymentsPersonalPensionCardSubtitle', {
            month: monthYearString,
        });

        this.latestPaymentsPersonalPensionCardData = {
            amountString: this.localeService.localizeNumber(total, 'currency'),
            description,
        };
    };

    private sumLatestPaymentMonthPayments(
        payments: { date: Date; amount: number }[]
    ): { total: number; monthYearString: string } | undefined {
        if (payments.length == 0) {
            return;
        }

        const latestDate = payments.reduce((latest, payment) => {
            const date = new Date(payment.date);
            return date > latest ? date : latest;
        }, new Date(0)); // Start with the earliest possible date

        if (latestDate < getLatestPensionPaymentCutoffDate()) {
            return;
        }

        // Filter payments that match the latest payment date
        const paymentsOnLatestMonth = payments.filter((payment) => {
            const date = new Date(payment.date);
            return date.getMonth() === latestDate.getMonth() && date.getFullYear() === latestDate.getFullYear();
        });

        if (paymentsOnLatestMonth.length == 0) {
            return;
        }

        // Calculate the total amount for payments on the latest date
        const totalAmount = paymentsOnLatestMonth.reduce((sum, payment) => sum + payment.amount, 0);

        const month = MonthIdentifiers[latestDate.getMonth()];
        const monthName = this.translocoService.translate(month.translationKey);
        // Not strictly necessary but in english month names are always capitalized but not in icelandic, so the name is only cast to lowercase if the language is not 'en'
        const monthYearString = `${this.translocoService.getActiveLang() == 'en' ? monthName : monthName.toLocaleLowerCase()} ${latestDate.getFullYear()}`;

        return {
            total: totalAmount,
            monthYearString,
        };
    }

    processLoans = (res: ExposedListLibraLoanInfoRes[]) => {
        if (!res || res.length == 0) {
            return;
        }

        const totalCurrentBalance = res.reduce((sum, loan) => sum + loan.currentBalance, 0);

        this.loansCardData = {
            amountString: this.localeService.localizeNumber(totalCurrentBalance, 'currency'),
            description: this.translocoService.translate('mpoLoansCardSubtitle'),
        };

        this.loansTableData = {
            headerTitles: [
                'mpoTableHeaderLoanNumber',
                'mpoTableHeaderIssueDate',
                'mpoTableHeaderStatus',
                'mpoTableHeaderOriginalBalance',
                'mpoTableHeaderCurrentBalance',
                'mpoTableHeaderInterest',
            ],

            rows: res.map((l) => {
                return {
                    rowTitles: [
                        l.loanNumber.toString(),
                        formatDate(l.issueDate, 'dd.MM.yyyy', 'en-EN'),
                        getLoanStatus(l.loanStatusNumber).translationKey,
                        this.localeService.localizeNumber(l.originalAmount, 'currency'),
                        this.localeService.localizeNumber(l.currentBalance, 'currency'),
                        this.localeService.localizeNumber(
                            l.interests / 100,
                            'percent',
                            this.localeService.getLocale(),
                            { maximumFractionDigits: 2 }
                        ),
                    ],
                } as TableRowData;
            }),
        };
    };
}
