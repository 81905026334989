import { Location } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { checkBrowser } from '../../../../helpers/checkBrowser';
import { ApplicationSystems } from '../../../../helpers/constants/application-systems.constants';
import { BrowserConstants } from '../../../../helpers/constants/browser.constants';
import { ApplicationInfo } from '../../../../interfaces/support/applications/applicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-common-pension',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    templateUrl: 'common-pension-applications.component.html',
    styleUrl: './common-pension-applications.component.scss',
    host: { class: 'main__content' },
})
export class CommonPensionApplicationsComponent implements AfterViewInit {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';

    public selectedApplication: ApplicationInfo | undefined;
    public applications: ApplicationInfo[] = [];
    BrowserConstants = BrowserConstants;
    ApplicationSystems = ApplicationSystems;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location,
        private translocoService: TranslocoService
    ) {
        this.initApplications();

        this.translocoService.langChanges$.subscribe(() => {
            this.initApplications();
        });
    }

    ngAfterViewInit(): void {
        if (this.dialog) {
            // Timeout is necessary to fix console error ExpressionChangedAfterItHasBeenCheckedError
            window.setTimeout(() => {
                this.checkUrl();
            });
        }
    }

    /**
     * Opens the pension application dialog for the selected application.
     * Retrieves a token for authentication and sets up the redirect URL.
     * @param application The pension application to open
     */
    openApplication(application: ApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => {
            this.location.go(`/lifeyrir/umsoknir/${pathSuffix}`);
        };

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        updateUrlPath(application.identifier);

        if (application.system === ApplicationSystems.Coredata) {
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
                .subscribe((token) => {
                    this.url = `${environment.coredataPortalUrl}?token=${token}`;
                    this.redirectUrl = application.url;
                    application.isLoading = false;
                    openDialogAndSubscribeClose();
                });
        } else {
            this.url = application.url;
            application.isLoading = false;
            openDialogAndSubscribeClose();
        }
    }

    /**
     * Checks the current route for specific application parameters and,
     * if present, opens the corresponding application.
     */
    checkUrl() {
        const first = this.route.snapshot.paramMap.get('identifier1');
        const second = this.route.snapshot.paramMap.get('identifier2');
        if (!first) {
            return;
        }

        let identifier = first;
        if (second) {
            identifier += `/${second}`;
        }

        const application = this.applications.find((x) => x.identifier == identifier);
        this.openApplication(application as ApplicationInfo);
    }

    /**
     * Initializes the available pension applications with their respective details.
     */
    initApplications() {
        this.applications = [
            {
                titleTranslation: 'mpaPensionPensionApplicationTitle',
                descriptionTranslation: 'mpaPensionPensionApplicationDescription',
                url: `${environment.coredataPortalUrl}/18/forms/39/`,
                identifier: '18/39',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionAverageAndSuccessorRuleTitle',
                descriptionTranslation: 'mpaPensionAverageAndSuccessorRuleDescription',
                url: `${environment.coredataPortalUrl}/18/forms/40/`,
                identifier: '18/40',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPension32Or95RuleTitle',
                descriptionTranslation: 'mpaPension32Or95RuleDescription',
                url: `${environment.coredataPortalUrl}/18/forms/41/`,
                identifier: '18/41',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionSpousalPensionTitle',
                descriptionTranslation: 'mpaPensionSpousalPensionDescription',
                url: `${environment.coredataPortalUrl}/18/forms/42/`,
                identifier: '18/42',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionDisabilityTitle',
                descriptionTranslation: 'mpaPensionDisabilityDescription',
                url: `${environment.coredataPortalUrl}/18/forms/51/`,
                identifier: '18/51',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionReassessmentOfDisabilityTitle',
                descriptionTranslation: 'mpaPensionReassessmentOfDisabilityDescription',
                url: `${environment.coredataPortalUrl}/18/forms/55/`,
                identifier: '18/55',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
        ];

        if (this.translocoService.getActiveLang() === 'en') {
            this.applications.unshift(
                ...[
                    {
                        titleTranslation: 'mpaPensionPensionApplicationTitleEnglish',
                        descriptionTranslation: 'mpaPensionPensionApplicationDescription',
                        url: 'https://fill.taktikal.is/s/5d8bf353fc4c',
                        identifier: 'eftirlaun-en',
                        system: ApplicationSystems.Taktikal,
                        isLoading: false,
                    },
                    {
                        titleTranslation: 'mpaReimbursementOfPensionContributionForeignCitizens',
                        descriptionTranslation: 'mpaReimbursementOfPensionContributionForeignCitizensDescription',
                        url: 'https://fill.taktikal.is/s/754de9cd0cc3',
                        identifier: 'endurgreidsla-lifeyris-en',
                        system: ApplicationSystems.Taktikal,
                        isLoading: false,
                    },
                ]
            );
        }
    }

    checkBrowser(): string {
        return checkBrowser();
    }

    getPageSubtitle = () => {
        if (this.translocoService.getActiveLang() === 'en') {
            return 'mpaPensionApplicationsSubtitle';
        }
        return null;
    };
}
