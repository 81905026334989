<div class="settings">
    <div class="settings__title">
        <lsr-title text="{{ 'mpsPageTitle' | transloco }}"></lsr-title>
    </div>

    <div class="settings__section settings__section--user">
        <form [formGroup]="contactForm">
            <h2 class="settings__headline headline3" tabindex="0">{{ 'mpsInformation' | transloco }}</h2>
            <div class="grid">
                <lsr-input label="{{ 'mpsName' | transloco }}" name="name" formControlName="name"></lsr-input>
                <lsr-input label="{{ 'mpsSSN' | transloco }}" formControlName="ssn"></lsr-input>
                <lsr-input label="{{ 'mpsPhone' | transloco }}" formControlName="phone"></lsr-input>
                <lsr-input
                    label="{{ 'mpsEmail' | transloco }}"
                    (blur)="setContactValidationMessages()"
                    [modifier]="
                        !contactForm.controls.email.valid && !contactForm.controls.email.disabled
                            ? 'input-group--error'
                            : ''
                    "
                    [errorMessage]="emailErrorMessage"
                    name="email"
                    formControlName="email"
                ></lsr-input>
            </div>
        </form>
    </div>

    @if (bankAccountRes) {
        <div class="settings__section settings__section--bank">
            <form [formGroup]="bankForm">
                <h2 class="settings__headline headline3" tabindex="0">{{ 'mpsTitleBank' | transloco }}</h2>
                <div class="settings__bank-details">
                    <lsr-input
                        label="{{ 'mpsBank' | transloco }}"
                        [modifier]="
                            !validBankAccount || (!bankForm.controls.bank.valid && bankForm.controls.bank.enabled)
                                ? 'input-group--error'
                                : ''
                        "
                        formControlName="bank"
                        placeholder="0000"
                    ></lsr-input>
                    <lsr-input
                        label="{{ 'mpsLedger' | transloco }}"
                        [modifier]="
                            !validBankAccount || (!bankForm.controls.ledger.valid && bankForm.controls.ledger.enabled)
                                ? 'input-group--error'
                                : ''
                        "
                        formControlName="ledger"
                        placeholder="00"
                    ></lsr-input>
                    <lsr-input
                        label="{{ 'mpsBankAccount' | transloco }}"
                        [modifier]="
                            !validBankAccount || (!bankForm.controls.account.valid && bankForm.controls.account.enabled)
                                ? 'input-group--error'
                                : ''
                        "
                        formControlName="account"
                        placeholder="000000"
                    ></lsr-input>
                    @if (validatingBankAccount) {
                        <lsr-loader
                            class="bank-account-validation-loader"
                            loadingText="{{ 'mpsLoaderBankAccountVerification' | transloco }}"
                        ></lsr-loader>
                    }
                </div>
                @if (validBankAccount && showBankAccountUpdateAlertMessage) {
                    <div class="bank-section__message body2">
                        <p tabindex="0">
                            {{ 'mpsInfoBankAccountsValid' | transloco }}
                        </p>
                    </div>
                } @else if (!validBankAccount) {
                    <div class="bank-section__message bank-section__message--error body2">
                        <p tabindex="0">
                            {{ 'mpsInfoBankAccountsNotValid' | transloco }}
                        </p>
                    </div>
                }
            </form>
        </div>
    }
    
    @if (taxDetailsRes) {
        <h2 class="settings__headline headline3" tabindex="0">{{ 'mpsTaxBracketsAndPersonalTaxCredit' | transloco }}</h2>
        @if (taxConfigsRes?.isDateValidToUpdateTaxDetails) {
            <div class="settings_tax-section__description richtext body2">
                <p tabindex="0">
                    {{ 'mpsTaxDetailsMessage' | transloco }}
                </p>
                <ul [innerHtml]="'mpsTaxDetailsSubMessage' | transloco | sanitizeHtml">
                </ul>
            </div>
            <lsr-button
                    class="settings-tax-details-button"
                    text="{{ 'mpsTaxDetailsButton' | transloco }}"
                    modifier="button--secondary button--red "
                    buttonType="click"
                    (clickEvent)="taxDescription()"
                >
            </lsr-button>
        }
        @if (!taxConfigsRes?.isDateValidToUpdateTaxDetails) {
            <div class="settings_tax-section__description_invalid_date body2">
                <p tabindex="0">
                    {{ 'mpsTaxDetailsNoUpdate' | transloco }}
                </p>
            </div>
        }

        @if (taxDetailsRes.taxBrackets) {
            <div class="settings__section settings__section--tax-bracket">
                <form [formGroup]="taxBracketsForm">
                    <h2 class="settings__headline headline3" tabindex="0">{{ 'mpsTaxBrackets' | transloco }}</h2>
                    <lsr-radio
                        value="SK1"
                        name="brackets"
                        [selectedGroupValue]="taxBracketsForm.value.taxBrackets ?? ''"
                        formControlName="taxBrackets"
                        label="{{ 'mpsTaxBracket1' | transloco }}"
                    ></lsr-radio>
                    <lsr-radio
                        value="SK2"
                        name="brackets"
                        [selectedGroupValue]="taxBracketsForm.value.taxBrackets ?? ''"
                        formControlName="taxBrackets"
                        label="{{ 'mpsTaxBracket2' | transloco }}"
                    ></lsr-radio>
                    <lsr-radio
                        name="brackets"
                        value="SK3"
                        [selectedGroupValue]="taxBracketsForm.value.taxBrackets ?? ''"
                        formControlName="taxBrackets"
                        label="{{ 'mpsTaxBracket3' | transloco }}"
                    ></lsr-radio>
                    <lsr-radio
                        name="brackets"
                        value="other"
                        [selectedGroupValue]="taxBracketsForm.value.taxBrackets ?? ''"
                        formControlName="taxBrackets"
                        label="{{ 'mpsTaxBracketsOtherSalary' | transloco }}"
                    ></lsr-radio>
                    @if (taxBracketsForm.value.taxBrackets === TaxDetailsValue.OTHER) {
                        <div class="settings__tax-discount-settings col align-self-end">
                            <div class="settings__input-wrapper-half">
                                <lsr-input
                                    type="text"
                                    name="taxBracketsOther"
                                    formControlName="taxBracketsOther"
                                    label="{{ 'mpsTaxBracketsOtherSalaryValue' | transloco }}"
                                    [isRequired]="true"
                                    suffix="kr."
                                    format="number"
                                    (blur)="setTaxBracketsValidationMessages()"
                                    [modifier]="
                                        !taxBracketsForm.controls.taxBracketsOther.valid && !taxBracketsForm.controls.taxBracketsOther.disabled
                                            ? 'input-group--error'
                                            : ''
                                    "
                                    [errorMessage]="otherValueErrorMessage"
                                ></lsr-input>
                            </div>
                        </div>
                    }
                </form>
            </div>
        }

        @if (taxDetailsRes.personalTaxCredit) {
            <div class="settings__section settings__section--tax-discount">
                <form [formGroup]="taxCreditForm">
                    <h2 class="settings__headline headline3" tabindex="0">{{ 'mpsPersonalTaxCredit' | transloco }}</h2>
                    <lsr-radio
                        value="noPersonalTaxCredit"
                        name="taxCredit"
                        formControlName="personalTaxCredit"
                        [selectedGroupValue]="taxCreditForm.value.personalTaxCredit ?? ''"
                        label="{{ 'mpsPersonalTaxCreditNotUsedLSR' | transloco }}"
                    ></lsr-radio>
                    <lsr-radio
                        value="personalTaxCredit"
                        name="taxCredit"
                        formControlName="personalTaxCredit"
                        [selectedGroupValue]="taxCreditForm.value.personalTaxCredit ?? ''"
                        label="{{ 'mpsPersonalTaxCreditUsedLSR' | transloco }}"
                    ></lsr-radio>
                    @if (taxCreditForm.value.personalTaxCredit === TaxDetailsValue.PERSONAL_TAX_CREDIT) {
                        <div class="settings__tax-discount-settings">
                            <lsr-checkbox
                                type="checkbox"
                                label="{{ 'mpsPersonalTaxCreditUpdate' | transloco }}"
                                formControlName="addNewPersonalTaxCredit"
                            ></lsr-checkbox>
                            @if (taxCreditForm.value.addNewPersonalTaxCredit) {
                                <div class="settings__tax-discount-settings-wrapper">
                                    <div class="settings__input-wrapper">
                                        <lsr-input
                                            type="number"
                                            label="{{ 'mpsPersonalTaxCreditUpdatedValue' | transloco }}"
                                            value=""
                                            icon="percentage"
                                            iconColor="grey"
                                            [isRequired]="true"
                                            formControlName="personalTaxCreditValue"
                                        ></lsr-input>
                                        <lsr-select
                                            class="search-bar__select"
                                            label="{{ 'mpsPersonalTaxCreditValidFrom' | transloco }}"
                                            formControlName="personalTaxCreditValidFrom"
                                            id="personalTaxCreditValidFrom"
                                            [options]="dateSelectionList"
                                            [isRequired]="true"
                                        >
                                        </lsr-select>
                                    </div>
                                </div>
                            }

                            <div class="settings__tax-discount-settings-wrapper">
                                <div class="settings_tax_details_table">
                                    <lsr-table-expandable
                                        [data]="personalTaxCreditTableData"
                                    >
                                    </lsr-table-expandable>
                                </div>
                            </div>
                        </div>
                    }
                    @if (taxDetailsRes?.personalTaxCredit?.usePersonalTaxCredit && taxCreditForm.value.personalTaxCredit === TaxDetailsValue.NO_PERSONAL_TAX_CREDIT) {
                    <div class="settings__tax-discount-settings body2">
                        <p tabindex="0"
                            [innerHtml]="'mpsAccumulatedTaxCreditMessage' | transloco | sanitizeHtml">
                        </p>
                    </div>
                    }
                </form>
            </div>
        }

        @if (taxDetailsRes.spousePersonalTaxCredit) {
            <div class="settings__section settings__section--tax-discount">
                <form [formGroup]="taxCreditSpouseForm">
                    <h2 class="settings__headline headline3" tabindex="0">
                        {{ 'mpsSpousePersonalTaxCredit' | transloco }}
                    </h2>
                    @if (!taxDetailsRes.spousePersonalTaxCredit.usePersonalTaxCredit) {
                        <div class="settings_tax-section__description_spouse body2">
                            <p tabindex="0">
                                {{ 'mpsSpousePersonalTaxCreditNotUsedLSR' | transloco }}
                            </p>
                        </div>
                    }
                    @if (taxDetailsRes.spousePersonalTaxCredit.usePersonalTaxCredit) {
                        <div class="settings_tax-section__description_spouse body2">
                            <p tabindex="0">
                                {{ 'mpsSpousePersonalTaxCreditUsedLSR' | transloco }}
                            </p>
                        </div>
                        <div class="settings__tax-discount-settings">
                            <div class="settings_tax_details_table">
                                <lsr-table-expandable
                                    [data]="spousePersonalTaxCreditTableData"
                                >
                                </lsr-table-expandable>
                            </div>
                        </div>
                    }
                </form>
            </div>
        }
    }
</div>
<lsr-bottom-bar [show]="contactForm.dirty || bankForm.dirty || taxBracketsForm.dirty || taxCreditForm.dirty">
    <div class="settings-bottom-bar-container">
        <lsr-button
            text="{{ 'mpsButtonSaveSettings' | transloco }}"
            [isDisabled]="!canSave()"
            [isLoading]="saving"
            modifier="button--default"
            (click)="save()"
        >
        </lsr-button>
    </div>
</lsr-bottom-bar>

<lsr-dialog-confirm #unmountConfirm headerTitle="{{ 'mpsHeaderTitleNote' | transloco }}">
    <p>{{ 'mpsInfoLeavePageConfirmation' | transloco }}</p>
</lsr-dialog-confirm>
