<div
    class="{{ identifier }} input-group input-group--icon input-group--select {{ modifier }} {{
        isDisabled ? 'input-group--disabled' : ''
    }} {{ selectedOption ? 'input-group--filled' : '' }}"
>
    <!-- If the label is not empty, add the label element -->
    @if (label.length > 0) {
        <label class="label {{ isLabelHidden ? 'label--hidden' : '' }}" for="{{ id }}">
            {{ label }}
            @if (isRequired) {
                <span class="label__asterisk">*</span>
            }
        </label>
    }

    <!-- This wrap element is needed for positioning extra elements  -->
    <div class="input-group__wrap">
        <select
            class="input-group__input"
            [(ngModel)]="selectedOption"
            (change)="onSelectChange($event)"
            name="{{ name }}"
            id="{{ id }}"
            [disabled]="isDisabled"
        >
            <option hidden [value]="placeholderValue">{{ placeholder | transloco }}</option>
            @if (clearable) {
                <option [value]="null">--</option>
            }
            @for (option of options; track option) {
                <option value="{{ option.value }}" [selected]="option.value === selectedOption">
                    {{ option.title | transloco }}
                </option>
            }
        </select>
        <div class="input-group__icon">
            <lsr-icon icon="chevron-down"></lsr-icon>
        </div>
    </div>
    <div aria-hidden="true" class="input-group__error">{{ errorMessage }}</div>
</div>
