<header class="header">
    <div class="container">
        <div class="header__container">
            <a class="header__logo" href="/" title="{{ 'mphBackToFrontPage' | transloco }}">
                <lsr-icon icon="logo"></lsr-icon>
                Lífeyrissjóður starfsmanna ríkisins
            </a>
            <div class="header__navigation">
                <div class="header__navigation-wrapper">
                    <lsr-navigation
                        [routes]="routes"
                        identifier="header-navigation"
                        [displayChildren]="false"
                        [displayPriority]="true"
                    ></lsr-navigation>
                </div>
            </div>
            <div class="header__buttons">
                <!-- Search -->
                <!-- <lsr-button buttonType="link" modifier="button--secondary button--big button--red" text="Skilaboð" href="/skilabod" icon="message"></lsr-button> -->
                <!-- Language -->
                @for (lang of translocoService.getAvailableLangs(); track lang) {
                    @if (lang !== translocoService.getActiveLang()) {
                        <lsr-button
                            buttonType="button"
                            modifier="button--secondary button--square button--square-text button--big button--red"
                            text="{{ lang.toString().toUpperCase() }}"
                            title="{{ 'butChooseLanguage' | transloco }}"
                            (click)="setLang(lang.toString())"
                        ></lsr-button>
                    }
                }
                <!-- Settings -->
                <lsr-button
                    buttonType="link"
                    modifier="button--secondary button--square button--big button--red"
                    text="{{ 'mphSettings' | transloco }}"
                    icon="user"
                    routerLink="/stillingar"
                ></lsr-button>
                <!-- Logout -->
                <lsr-button
                    buttonType="button"
                    identifier="header__logout-button"
                    modifier="button--default button--big"
                    text="{{ 'mphLogout' | transloco }}"
                    icon="padlock"
                    [isLoading]="isLoggingOut"
                    (click)="logoutClick()"
                ></lsr-button>
            </div>
        </div>
    </div>
</header>
