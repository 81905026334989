<div class="confirmation-message">
    @if (loading) {
        <div class="loader-wrapper">
            <lsr-loader loadingText="{{ 'mpePageLoadingText' | transloco }}"></lsr-loader>
        </div>
    }
    @if (success !== undefined) {
        @if (success) {
            <div class="confirmation-message__header">
                <lsr-icon icon="email" modifier="large"></lsr-icon>
                <lsr-title text="{{ 'mpecdEmailConfirmed' | transloco }}"></lsr-title>
            </div>
            <p class="body1">{{ 'mpecdEmailConfirmedText' | transloco }}</p>
            <lsr-button
                buttonType="link"
                text="{{ 'mpecGoToOverview' | transloco }}"
                modifier="button--secondary"
                href="/yfirlit"
            ></lsr-button>
        } @else {
            <div class="confirmation-message__header">
                <lsr-icon icon="email" modifier="large"></lsr-icon>
                <lsr-title text="{{ 'mpecError' | transloco }}"></lsr-title>
            </div>
            <p class="body1">{{ 'mpecdEmailNotConfirmedText' | transloco }}</p>
            <lsr-button
                buttonType="link"
                text="{{ 'mpecGoToLogin' | transloco }}"
                modifier="button--secondary"
                href="/login"
            ></lsr-button>
        }
    } 
</div>
