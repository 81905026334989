import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedListDocumentReq } from '../../interfaces/requests/exposedListDocumentReq';
import { ExposedListDocumentRes } from '../../interfaces/responses/exposed/exposedListDocumentRes';
import { ExposedListDocumentTypeRes } from '../../interfaces/responses/exposed/exposedListDocumentTypeRes';
import { ExposedReadDocumentRes } from '../../interfaces/responses/exposed/exposedReadDocumentRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';
import { MessageService } from '../message/message.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {}

    public listTypes(): Observable<ExposedListDocumentTypeRes[]> {
        return this.http
            .get<HttpStatus<ExposedListDocumentTypeRes[]>>(environment.localApi + 'skjalamidja/listTypes')
            .pipe(
                map((res: HttpStatus<ExposedListDocumentTypeRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of([]);
                })
            );
    }

    public listDocuments(req: ExposedListDocumentReq): Observable<ExposedListDocumentRes[]> {
        return this.http
            .post<HttpStatus<ExposedListDocumentRes[]>>(environment.localApi + 'skjalamidja/listDocumentsPublic', req)
            .pipe(
                map((res: HttpStatus<ExposedListDocumentRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of([]);
                })
            );
    }

    public readDocument(documentId: number): Observable<ExposedReadDocumentRes | null> {
        return this.http
            .get<HttpStatus<ExposedReadDocumentRes>>(environment.localApi + 'skjalamidja/' + documentId)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((res: HttpErrorResponse) => {
                    this.messageService.handleErrorResponse(res);
                    return of(null as ExposedReadDocumentRes | null);
                })
            );
    }
}
