import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LSRIconComponent } from '../../lsr-icon/lsr-icon.component';

@Component({
    selector: 'lsr-radio',
    standalone: true,
    imports: [FormsModule, LSRIconComponent],
    templateUrl: `lsr-input-radio.component.html`,
    styleUrl: `../lsr-input-base.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-input-radio' },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LSRInputRadioComponent,
            multi: true,
        },
    ],
})
export class LSRInputRadioComponent implements ControlValueAccessor {
    @Input() value: string = '';
    @Input() selectedGroupValue: string = '';
    @Input() name: string = '';
    @Input() label: string = '';
    @Input() isRequired?: boolean;
    @Input() identifier: string = '';
    @Input() modifier: string = '';
    @Input() isLabelHidden?: boolean;
    @Input() isDisabled: boolean = false;
    @Input() id: string = '';
    @Input() errorMessage: string = '';
    @Input() formControlName: string = '';

    private onChange: ((value: string) => void) | null = null;
    private onTouched: (() => void) | null = null;

    // Called when the value of the radio button changes
    writeValue(value: string): void {
        this.selectedGroupValue = value;
    }

    // Registers the onChange function (called by Angular when the value changes)
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    // Registers the onTouched function (called by Angular when the control is touched)
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    selectValue(): void {
        if (this.onChange && this.onTouched) {
            this.onChange(this.value);
            this.onTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
