<footer class="footer">
    <div class="container">
        <div class="footer__container richtext body2">
            <div class="grid">
                <div class="footer__column footer__column--logo">
                    <a class="footer__logo" href="/" title="{{ 'mpfBackToFrontPage' | transloco }}">
                        <lsr-icon icon="logo"></lsr-icon>
                        Lífeyrissjóður starfsmanna ríkisins
                    </a>
                </div>
                <div class="footer__column footer__column--text">
                    <div class="footer__text">
                        <p class="footer__text-headline headline4" tabindex="0">
                            {{ companyName }}
                        </p>
                        <p tabindex="0">{{ companyAddress }}</p>
                        <p>
                            <a href="{{ companyLocation }}" rel="noopener noreferrer" target="_blank">{{
                                'mpfSeeLocation' | transloco
                            }}</a>
                        </p>
                        <p>
                            <a class="footer__text-terms" [routerLink]="" (click)="openTermsOfService()">{{
                                'mpfTerms' | transloco
                            }}</a>
                        </p>
                    </div>
                    @if (companyPhone) {
                        <div class="footer__text">
                            <p class="footer__text-headline headline4" tabindex="0">{{ 'mpfPhone' | transloco }}</p>
                            <p tabindex="0">
                                <strong
                                    ><a href="tel:@text.companyPhone">{{ companyPhone }}</a></strong
                                >
                            </p>
                        </div>
                    }
                    @if (openingHours) {
                        <div class="footer__text">
                            <p class="footer__text-headline headline4" tabindex="0">
                                {{ 'mpfOpeningHoursText' | transloco }}
                            </p>
                            <div class="footer__text-opening-hours">
                                @for (opening of openingHours; track opening[0]; let index = $index) {
                                    <p tabindex="0">
                                        {{ opening[0] }}
                                        <strong>{{ opening[1] }}</strong>
                                    </p>
                                }
                            </div>
                        </div>
                    }
                    <div class="footer__logos">
                        <picture class="picture picture--eager">
                            <source
                                media="(min-resolution: 120dpi) and (max-width: 768px)"
                                srcset="/assets/images/lifeyrismal--300.png"
                                width="300"
                                height="120"
                            />
                            <source
                                media="(min-resolution: 120dpi) and (min-width: 769px)"
                                srcset="/assets/images/lifeyrismal--400.png"
                                width="400"
                                height="160"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcset="/assets/images/lifeyrismal--200.png"
                                width="200"
                                height="80"
                            />
                            <img
                                alt="Lifeyrismal"
                                class="picture__image"
                                src="/assets/images/lifeyrismal--150.png"
                                width="150"
                                height="60"
                                loading="eager"
                                decoding="async"
                            />
                        </picture>
                        <picture class="picture picture--eager">
                            <source
                                media="(min-resolution: 120dpi) and (max-width: 768px)"
                                srcset="/assets/images/jafnlaunavottun-2021-2024--300.png"
                                width="300"
                                height="120"
                            />
                            <source
                                media="(min-resolution: 120dpi) and (min-width: 769px)"
                                srcset="/assets/images/jafnlaunavottun-2021-2024--400.png"
                                width="400"
                                height="160"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcset="/assets/images/jafnlaunavottun-2021-2024--200.png"
                                width="200"
                                height="80"
                            />
                            <img
                                alt="Jafnlaunavottun 2021 2024"
                                class="picture__image"
                                src="/assets/images/jafnlaunavottun-2021-2024--150.png"
                                width="150"
                                height="60"
                                loading="eager"
                                decoding="async"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<lsr-dialog-basic headerTitle="{{ 'mpfTermsDialogTitle' | transloco }}">
    <div class="terms__body" [innerHtml]="'mpfTermsDialogText' | transloco | sanitizeHtml"></div>
</lsr-dialog-basic>
