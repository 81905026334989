import { BrowserConstants } from './constants/browser.constants';

export function checkBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    const vendor = window.navigator.vendor.toLowerCase();

    switch (true) {
        case agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1:
            return BrowserConstants.Edge;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        case agent.indexOf('opr') > -1 && !!(window as any).opr:
            return BrowserConstants.Edge;
        case agent.indexOf('chrome') > -1 && vendor.indexOf('google') > -1:
            return BrowserConstants.Chrome;
        case agent.indexOf('firefox') > -1:
            return BrowserConstants.Firefox;
        case agent.indexOf('trident') > -1:
            return BrowserConstants.Ie;
        case agent.indexOf('safari') > -1 && vendor.indexOf('apple') > -1:
            return BrowserConstants.Safari;
        default:
            return 'other';
    }
}
