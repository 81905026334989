import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { defaultChartOptions, PersonalPensionSavingsChartOptions } from '../../components/pages/personal-pension/personal-pension-chart-options';

@Injectable({
    providedIn: 'root',
})

export class ApexChartOptionsService {
    constructor(private translationService: TranslocoService) {}

    getApexChartOptions(): PersonalPensionSavingsChartOptions {
        return {
            ...defaultChartOptions,
            chart: {
                ...defaultChartOptions.chart,
            locales: [
                {
                    name: 'en',
                    options: JSON.parse(this.translationService.translateObject('mppApexChartLocalesOptions')),
                }]},
        } as PersonalPensionSavingsChartOptions;
    }

}