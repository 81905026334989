import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedListLibraLoanInfoRes } from '../../interfaces/responses/exposed/exposedListLibraLoanInfoRes';
import { ExposedReadDocumentRes } from '../../interfaces/responses/exposed/exposedReadDocumentRes';
import { ReadLoanEligibilityRes } from '../../interfaces/responses/Read/readLoanEligibilityRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';
import { CacheService } from '../cache/cache.service';
import { MessageService } from '../message/message.service';

@Injectable({
    providedIn: 'root',
})
export class LoansService {
    constructor(
        private http: HttpClient,
        private cacheService: CacheService,
        private messageService: MessageService
    ) {}

    public loans(): Observable<ExposedListLibraLoanInfoRes[]> {
        return this.cacheService
            .getOrFetch('loans', () =>
                this.http.get<HttpStatus<ExposedListLibraLoanInfoRes[]>>(environment.localApi + 'loans')
            )
            .pipe(
                map((res: HttpStatus<ExposedListLibraLoanInfoRes[]>) => res.returnItem),
                catchError((res: HttpErrorResponse) => {
                    this.messageService.handleErrorResponse(res);
                    return of([]);
                })
            );
    }

    public loanEligibility(ktala: string): Observable<ReadLoanEligibilityRes> {
        return this.http
            .get<HttpStatus<ReadLoanEligibilityRes>>(environment.localApi + 'loans/loanEligibility?ktala=' + ktala)
            .pipe(
                map((res: HttpStatus<ReadLoanEligibilityRes>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    }

    public loanEligibilityPdf(ktala: string): Observable<ExposedReadDocumentRes> {
        return this.http
            .get<HttpStatus<ExposedReadDocumentRes>>(environment.localApi + 'loans/loanEligibilityPdf?ktala=' + ktala)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    }
}
