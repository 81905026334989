import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private cache = new Map<string, { data: unknown; timestamp: number }>();
    // Cache expiry timeout after which data will always be refetched from the API
    private cacheTimeout = 1000 * 60 * 60;

    // Get value from cache or fetch from API
    getOrFetch<T>(key: string, fetchFunction: () => Observable<T>, saveOnError: boolean = false): Observable<T> {
        const cached = this.get<T>(key);
        if (cached) {
            return of(cached);
        }

        // Fetch new data and store in the cache
        return fetchFunction().pipe(
            tap((data) => {
                this.cache.set(key, { data, timestamp: Date.now() });
            }),
            catchError((error) => {
                if (error.status == 403 && saveOnError) {
                    this.cache.set(key, { data: null, timestamp: Date.now() });
                }
                throw error;
            })
        );
    }

    get<T>(key: string): T | null {
        const cached = this.cache.get(key);

        // If there exists a value in the cache and it has not expired then the cached value is returned
        if (cached && Date.now() - cached.timestamp < this.cacheTimeout) {
            return cached.data as T;
        }

        this.cache.delete(key);
        return null;
    }

    set(key: string, data: unknown) {
        this.cache.set(key, { data, timestamp: Date.now() });
    }

    clearCache(key?: string): void {
        if (key) {
            this.cache.delete(key);
        } else {
            this.cache.clear();
        }
    }
}
