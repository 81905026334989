/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexMarkers,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';

export interface PersonalPensionSavingsChartOptions {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
    stroke: ApexStroke;
    tooltip: ApexTooltip;
    markers: ApexMarkers;
    fill: ApexFill;
    yaxis: ApexYAxis;
    annotations: ApexAnnotations;
    colors: any;
    toolbar: any;
    grid: any;
}

export const defaultChartOptions = {
    chart: {
        defaultLocale: 'en',
        selection: {
            enabled: false,
        },
        type: 'area',
        height: 300,
        animations: {
            speed: 400,
            animateGradually: {
                enabled: false,
            },
        },
        fontFamily: 'inherit',
        foreColor: 'inherit',
        width: '100%',
        toolbar: {
            show: false,
        },
        events: {
            dataPointMouseEnter: function (event: any) {
                event.fromElement.style.cursor = 'pointer';
            },
        },
        zoom: {
            enabled: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 0,
    },
    xaxis: {
        type: 'datetime',
        tickAmount: 6,
    },
    yaxis: {
        min: 0,
        decimalsInFloat: 0,
        tickAmount: 6,
        forceNiceScale: true,
        labels: {
            formatter: function (val: string | number) {
                let value = val.toLocaleString();
                value = value.replace(/,/g, '.');
                return value;
            },
        },
    },
    fill: {
        type: 'solid',
        opacity: 0.4,
    },
} as PersonalPensionSavingsChartOptions;
