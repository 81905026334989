import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/guards/auth.guard';
import { NoAuthGuard } from '../auth/guards/noAuth.guard';
import { AuthLayoutComponent } from '../components/layouts/auth-layout/auth-layout.component';
import { NoAuthLayoutComponent } from '../components/layouts/no-auth-layout/no-auth-layout.component';
import { CalculatorComponent } from '../components/pages/calculator/calculator.component';
import { CommonPensionApplicationsComponent } from '../components/pages/common-pension/common-pension-applications/common-pension-applications.component';
import { CommonPensionComponent } from '../components/pages/common-pension/common-pension.component';
import { DocumentsComponent } from '../components/pages/documents/documents.component';
import { EmailConfirmationComponent } from '../components/pages/email-confirmation/email-confirmation.component';
import { ImpersonateComponent } from '../components/pages/impersonate/impersonate.component';
import { LoanApplicationsComponent } from '../components/pages/loans/applications/loans-applications.component';
import { LoansComponent } from '../components/pages/loans/loans.component';
import { LoginComponent } from '../components/pages/login/login.component';
import { OverviewComponent } from '../components/pages/overview/overview.component';
import { PersonalPensionApplicationsComponent } from '../components/pages/personal-pension/applications/personal-pension-applications.component';
import { PersonalPensionComponent } from '../components/pages/personal-pension/personal-pension.component';
import { PersonalPensionSpecifiedComponent } from '../components/pages/personal-pension/specified/personal-pension-specified.component';
import { PersonalPensionTraditionalComponent } from '../components/pages/personal-pension/traditional/personal-pension-traditional.component';
import { SettingsComponent } from '../components/pages/settings/settings.component';
import { canDeactivateGuard } from '../helpers/guards/canDeactivateGuard';

export const routes: Routes = [
    // Redirect users from the root URL to the Overview page.
    {
        path: '',
        redirectTo: '/yfirlit',
        pathMatch: 'full',
        data: {
            domain: 'redirectEmptyUrl',
        },
    },
    {
        path: '',
        component: NoAuthLayoutComponent,
        children: [
            {
                path: 'stadfesting-netfangs/:token',
                component: EmailConfirmationComponent,
                data: {
                    layout: 'empty',
                },
            },
        ],
    },

    // Login page. Route accessible without authentication.
    {
        path: '',
        component: NoAuthLayoutComponent,
        canActivate: [NoAuthGuard],
        data: {
            domain: 'login',
        },
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    layout: 'empty',
                },
            },
        ],
    },

    // Protected routes accessible only to authenticated users.
    // This includes the main menu and its associated pages.
    {
        path: '',
        component: AuthLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            domain: 'menuItems',
        },
        children: [
            {
                path: 'yfirlit',
                component: OverviewComponent,
                data: {
                    name: 'mpgOverview',
                    layout: 'empty',
                    showInMenu: true,
                },
            },
            {
                path: 'lifeyrir',
                data: {
                    name: 'mpgPension',
                    layout: 'empty',
                    showInMenu: true,
                },
                children: [
                    {
                        path: '',
                        component: CommonPensionComponent,
                    },
                    {
                        path: 'umsoknir',
                        component: CommonPensionApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                    {
                        path: 'umsoknir/:identifier1',
                        component: CommonPensionApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                    {
                        path: 'umsoknir/:identifier1/:identifier2',
                        component: CommonPensionApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                ],
            },
            {
                path: 'sereign/umsoknir',
                component: PersonalPensionApplicationsComponent,
                data: {
                    name: 'Umsóknir',
                    layout: 'empty',
                    showInMenu: false,
                },
            },
            {
                path: 'sereign/umsoknir/:identifier1',
                component: PersonalPensionApplicationsComponent,
                data: {
                    name: 'Umsóknir',
                    layout: 'empty',
                    showInMenu: false,
                },
            },
            {
                path: 'sereign/umsoknir/:identifier1/:identifier2',
                component: PersonalPensionApplicationsComponent,
                data: {
                    name: 'Umsóknir',
                    layout: 'empty',
                    showInMenu: false,
                },
            },
            {
                path: 'sereign',
                component: PersonalPensionComponent,
                data: {
                    name: 'mpgPrivatePension',
                    layout: 'empty',
                    showInMenu: true,
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'hefdbundin-sereign',
                        pathMatch: 'full',
                    },
                    {
                        path: 'hefdbundin-sereign',
                        component: PersonalPensionTraditionalComponent,
                        data: {
                            name: 'mpgTraditionalPersonalPension',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'tilgreind-sereign-lsr',
                        component: PersonalPensionSpecifiedComponent,
                        data: {
                            name: 'mpgSpecifiedPersonalPension',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                ],
            },
            {
                path: 'lan',
                component: LoansComponent,
                data: {
                    name: 'mpgLoans',
                    layout: 'empty',
                    showInMenu: true,
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'yfirlit',
                        pathMatch: 'full',
                    },
                    {
                        path: 'yfirlit',
                        component: LoansComponent,
                        data: {
                            name: 'mpgOverview',
                            libraUrl: 'loans/overview',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'umsoknir',
                        component: LoansComponent,
                        data: {
                            name: 'mpgApplications',
                            libraUrl: 'applications/overview',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'samningar',
                        component: LoansComponent,
                        data: {
                            name: 'mpgAgreements',
                            libraUrl: 'agreements/overview',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'lanareiknivel',
                        component: LoansComponent,
                        data: {
                            name: 'mpgLoanCalculator',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'lansrettur',
                        component: LoansComponent,
                        data: {
                            name: 'mpgLoanEligibility',
                            layout: 'empty',
                            showInMenu: true,
                        },
                    },
                ],
            },
            {
                path: 'lanaumsoknir',
                data: {
                    name: 'Lánaumsóknir',
                    layout: 'empty',
                    showInMenu: false,
                },
                children: [
                    {
                        path: '',
                        component: LoanApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                    {
                        path: ':identifier1',
                        component: LoanApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                    {
                        path: ':identifier1/:identifier2',
                        component: LoanApplicationsComponent,
                        data: {
                            name: 'Umsóknir',
                            layout: 'empty',
                            showInMenu: false,
                        },
                    },
                ],
            },
            {
                path: 'lifeyrisreiknivel',
                component: CalculatorComponent,
                data: {
                    name: 'mpgPensionCalculator',
                    layout: 'empty',
                    showInMenu: true,
                },
            },
            {
                path: 'skjol',
                component: DocumentsComponent,
                data: {
                    name: 'mpgDocuments',
                    layout: 'empty',
                    showInMenu: true,
                },
            },
            {
                path: 'stillingar',
                component: SettingsComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    name: 'mpgSettings',
                    layout: 'empty',
                    showInMenu: true,
                },
            },
        ],
    },

    // Route for user impersonation.
    // This allows LSR employees to act on behalf of other users.
    {
        path: 'impersonate',
        component: ImpersonateComponent,
        data: {
            layout: 'empty',
            domain: 'impersonation',
        },
    },

    // Redirect users to the Overview page if no matching route is found.
    {
        path: '**',
        redirectTo: '/yfirlit',
        pathMatch: 'full',
        data: {
            domain: 'redirectUrlNotFound',
        },
        // Note: Consider adding a PageNotFoundComponent to display a user-friendly error message.
        // component: PageNotFoundComponent
    },
];
