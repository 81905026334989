<lsr-title
    text="{{ 'mpaLoanApplicationsTitle' | transloco }}"
    subtitle="{{ getPageSubtitle() | transloco }}"
></lsr-title>

<div class="container">
    <section class="grid grid--3-columns">
        @for (application of applications; track application; let index = $index) {
            <lsr-action-card
                title="{{ application.titleTranslation | transloco }}"
                text="{{ application.descriptionTranslation | transloco }}"
                [isDialogButton]="true"
                buttonText="{{ 'mpaRegisterApplicationButtonText' | transloco }}"
                (click)="openApplication(application)"
                [isLoading]="application.isLoading"
            ></lsr-action-card>
        }
    </section>
</div>

<lsr-dialog-basic headerTitle="{{ selectedApplication?.titleTranslation | transloco }}" headerIcon="application">
    @if (selectedApplication?.system === ApplicationSystems.Coredata && checkBrowser() === BrowserConstants.Safari) {
        <lsr-title subtitle="{{ 'mpaApplicationsSafari' | transloco }}"></lsr-title>
    } @else if (url !== '') {
        <lsr-iframe-wrapper
            class="full-width"
            iframeStyles="full-width"
            [showLoader]="true"
            loadingText="{{ 'mpaApplicationLoaderText' | transloco }}"
            [url]="url"
            [redirectUrl]="redirectUrl"
        ></lsr-iframe-wrapper>
    }
</lsr-dialog-basic>
