import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TranslocoCurrencyPipe, TranslocoLocaleService } from '@jsverse/transloco-locale';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRAmountCardComponent } from '@lsr/ui-components/lsr-amount-card';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRNavigationComponent } from '@lsr/ui-components/lsr-navigation';
import { LSRTableBoxComponent } from '@lsr/ui-components/lsr-table/lsr-table-box';
import { LSRTableExpandableComponent } from '@lsr/ui-components/lsr-table/lsr-table-expandable';
import { TableData, TableRowData } from '@lsr/ui-components/lsr-table/lsr-table-interfaces';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { base64ToArrayBuffer, saveByteArray } from '../../../../helpers/blobDownload';
import { PersonalPensionTypes } from '../../../../helpers/constants/personal-pension-types.constants';
import { ExposedKriaPaidContributionsYearGroupRes } from '../../../../interfaces/responses/exposed/exposedKriaPaidContributionsYearGroupRes';
import { ExposedKriaPensionPaymentsYearGroup } from '../../../../interfaces/responses/exposed/exposedKriaPensionPaymentsYearGroup';
import { ExposedListKriaPensionPaymentsPersonalPension } from '../../../../interfaces/responses/exposed/exposedListKriaPensionPaymentsPersonalPensionRes';
import { ExposedMeRes } from '../../../../interfaces/responses/exposed/exposedMeRes';
import {
    ExposedPersonalPensionSavingsRes,
    ExposedPersonalPensionTypeBalanceRes,
    ExposedSpecifiedPersonalPensionChartData,
} from '../../../../interfaces/responses/exposed/exposedPersonalPensionSavingsRes';
import { ContributionsService } from '../../../../services/contributions/contributions.service';
import { PensionService } from '../../../../services/pension/pension.service';
import { UserService } from '../../../../services/user/user.service';
import { ApexChartOptionsService } from '../../../../services/pension/apexChartOptionsService';
import { PersonalPensionSavingsChartOptions } from '../personal-pension-chart-options';

@Component({
    selector: 'app-personal-pension-specified',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRAmountCardComponent,
        LSRTableExpandableComponent,
        LSRTableBoxComponent,
        LSRNavigationComponent,
        LSRActionCardComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
        TranslocoCurrencyPipe,
        ChartComponent,
        NgApexchartsModule,
        LSRButtonComponent,
    ],
    templateUrl: 'personal-pension-specified.component.html',
    styleUrl: '../personal-pension.component.scss',
    host: { class: 'main__content' },
})
export class PersonalPensionSpecifiedComponent implements OnInit {
    @ViewChild('chart') chart?: ChartComponent;
    userDetails?: ExposedMeRes;
    chartOptions?: PersonalPensionSavingsChartOptions;
    currentDateString: string = formatDate(new Date(), 'dd.MM.yyyy', 'en-EN');

    savings?: ExposedPersonalPensionTypeBalanceRes<ExposedSpecifiedPersonalPensionChartData>;
    payments?: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[];
    contributions?: ExposedKriaPaidContributionsYearGroupRes[];

    investmentPlanTableData: TableData = {
        headerTitles: ['mpppTableHeaderInvestmentPlan', 'mpppTableHeaderBalance', 'mpppTableHeader12MonthYield'],
        rows: [],
    };

    paymentsTableData: TableData = {
        headerTitles: [
            'mpppTableHeaderYear',
            'mpppTableHeaderPayout',
            'mpppTableHeaderWithholdingTax',
            'mpppTableHeaderTotal',
        ],
        rows: [],
    };

    contributionsTableData: TableData = {
        headerTitles: ['mpppTableHeaderYear', 'mpppTableHeaderTotal'],
        rows: [],
    };

    loadingPersonalPensionSummary = false;

    constructor(
        private pensionService: PensionService,
        private contributionsService: ContributionsService,
        private localeService: TranslocoLocaleService,
        private translationService: TranslocoService,
        private translocoService: TranslocoService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private apexChartOptionsService: ApexChartOptionsService
    ) {}

    ngOnInit(): void {
        this.userService.currentUser
            .pipe(
                filter((x) => x != null),
                take(1)
            )
            .subscribe((user) => {
                this.userDetails = user;

                const requests = {} as {
                    savings: Observable<ExposedPersonalPensionSavingsRes>;
                    payments: Observable<
                        ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
                    >;
                    contributions: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
                };

                // These responses will all have been cached in the services so we will not be refetching here
                requests.savings = this.pensionService.getPersonalPensionSavings();
                requests.payments = this.pensionService.getPersonalPensionPayments(PersonalPensionTypes.Specified);
                requests.contributions = this.contributionsService.contributionsLSRTS();

                forkJoin(requests).subscribe((res) => {
                    if (res.savings.specifiedPersonalPension) {
                        this.processSavings(res.savings.specifiedPersonalPension);
                    }

                    if (res.payments) {
                        this.processPayments(res.payments);
                    }

                    if (res.contributions) {
                        this.processContributions(res.contributions);
                    }
                });
            });

        // Reprocess latest pension payments on language changes to update card data text translations
        this.translocoService.langChanges$.subscribe(() => {
            // setTimeout is necessary here to do this in the next tick, otherwise the translations will not be displayed correctly on the first language change
            setTimeout(() => {
                if (this.savings?.chartData) {
                    this.processChart(this.savings.chartData);
                }
            });
        });
    }

    processSavings = (res: ExposedPersonalPensionTypeBalanceRes<ExposedSpecifiedPersonalPensionChartData>) => {
        if (!res) {
            return;
        }

        this.savings = res;
        this.investmentPlanTableData = {
            ...this.investmentPlanTableData,
            rows: res.investmentPlans.map((row) => {
                return {
                    rowTitles: [
                        row.investmentPlan,
                        this.localeService.localizeNumber(row.balance, 'currency'),
                        this.localeService.localizeNumber(row.last12MonthYield / 100, 'percent', 'is-IS', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }),
                    ],
                } as TableRowData;
            }),
        };

        if (res.chartData) {
            this.processChart(res.chartData);
        }
    };

    processPayments = (res: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]) => {
        if (!res || res.length == 0) {
            return;
        }

        this.payments = res;
        this.paymentsTableData = {
            ...this.paymentsTableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [
                        row.year,
                        this.localeService.localizeNumber(row.paymentAfterTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentWithTaxTotal, 'currency'),
                    ],
                    innerTable: {
                        headerTitles: [
                            'mpppTableHeaderPaymentDate',
                            'mpppTableHeaderInvestmentPlan',
                            'mpppTableHeaderPaymentType',
                            'mpppTableHeaderTotal',
                        ],
                        rows: row.payments.map((p) => {
                            return {
                                rowTitles: [
                                    formatDate(p.paymentDate, 'dd.MM.yyyy', 'en-EN'),
                                    p.investmentPlanName,
                                    p.pensionName,
                                    this.localeService.localizeNumber(p.payment, 'currency'),
                                ],
                            } as TableRowData;
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processContributions = (res: ExposedKriaPaidContributionsYearGroupRes[]) => {
        if (!res || res.length == 0) {
            return;
        }

        this.contributions = res;
        this.contributionsTableData = {
            ...this.contributionsTableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [row.year, this.localeService.localizeNumber(row.total, 'currency')],
                    innerTable: {
                        headerTitles: [
                            'mpppTableHeaderMonthYear',
                            'mpppTableHeaderPaymentDate',
                            'mpppTableHeaderInvestmentPlan',
                            'mpppTableHeaderEmployer',
                            'mpppTableHeaderTotal',
                        ],
                        rows: row.contributions.map((c) => {
                            return {
                                rowTitles: [
                                    c.periodShort,
                                    formatDate(c.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                                    c.investmentPlanName,
                                    c.employerName,
                                    this.localeService.localizeNumber(c.total!, 'currency'),
                                ],
                            } as TableRowData;
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processChart = (data: ExposedSpecifiedPersonalPensionChartData[]) => {
        const apxChartOptions = this.apexChartOptionsService.getApexChartOptions();

        this.chartOptions = {
            ...apxChartOptions,
            colors: ['#912D27', '#0F8B8D'],
            series: [
                {
                    name: this.translationService.translate('mpppTraditionalChartOwnContribution'),
                    data: data.map((item) => ({ x: item.timeStamp, y: item.contribution })),
                },
                {
                    name: this.translationService.translate('mpppTraditionalChartBalanceWithInterest'),
                    data: data.map((item) => ({ x: item.timeStamp, y: item.contributionWithInterest })),
                },
            ],
            tooltip: {
                followCursor: true,
                theme: 'dark',
                x: {
                    format: 'dd.MM.yyyy',
                },
                y: {
                    formatter: (value: string | number): string => this.localeService.localizeNumber(value, 'currency'),
                },
            },
        } as PersonalPensionSavingsChartOptions;
    };

    getRegularPersonalPensionBalanceCardSubtitle = () => {
        const lines: string[] = [];
        if (this.savings?.last12MonthsPerformance) {
            lines.push(
                `${this.translationService.translate('mpppRegularSavingsBalanceCardSubtitle12MonthYield')}: ${this.localeService.localizeNumber(this.savings.last12MonthsPerformance, 'currency')}`
            );
        }

        if (this.savings?.contributionRatio) {
            lines.push(
                `${this.translationService.translate('mpppRegularSavingsBalanceCardSubtitleOwnContribution')}: ${this.localeService.localizeNumber(this.savings.contributionRatio, 'percent', undefined, { maximumFractionDigits: 1 })}`
            );
        }

        return lines.join('\n');
    };

    downloadPersonalPensionOverviewSummary = () => {
        const currentDate = new Date();
        const yearAgoDate = new Date();
        yearAgoDate.setFullYear(currentDate.getFullYear() - 1);

        const datePipe = new DatePipe('en-US');
        this.loadingPersonalPensionSummary = true;
        this.pensionService
            .getPersonalPensionOverviewSummary({
                ssn: this.userDetails!.ssn,
                paymentFromDate: datePipe.transform(yearAgoDate, 'YYYY-MM-dd')!,
                paymentToDate: datePipe.transform(currentDate, 'YYYY-MM-dd')!,
                showSalaryDate: true,
                letterMail: false,
            })
            .subscribe((res) => {
                const byteArray = base64ToArrayBuffer(res.base64File);
                saveByteArray(`Yfirlit_Sereign_${this.userDetails?.ssn}`, byteArray);
            })
            .add(() => {
                this.loadingPersonalPensionSummary = false;
            });
    };

    openApplication = () => {
        this.router.navigate(['../umsoknir', 23, 82], { relativeTo: this.route });
    };
}
