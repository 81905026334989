<header class="header">
    <div class="container">
        <div class="header__container">
            <div class="header__align-element"></div>
            <a class="header__logo" href="https://lsr.is" title="Til baka á forsíðu">
                <lsr-icon icon="logo"></lsr-icon>
                Lífeyrissjóður starfsmanna ríkisins
            </a>
            @for (lang of translocoService.getAvailableLangs(); track lang) {
                @if (lang !== translocoService.getActiveLang()) {
                    <div class="header__lang-button">
                        <lsr-button
                            buttonType="button"
                            modifier="button--secondary button--square button--square-text button--big button--red"
                            text="{{ lang.toString().toUpperCase() }}"
                            title="{{ 'butChooseLanguage' | transloco }}"
                            (click)="setLang(lang.toString())"
                        ></lsr-button>
                    </div>
                }
            }
        </div>
    </div>
</header>
<main class="main" id="main">
    <div class="container">
        <!-- Content from path rendered in router-outlet  -->
        <router-outlet></router-outlet>
    </div>
</main>
