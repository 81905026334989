import { Location } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { checkBrowser } from '../../../../helpers/checkBrowser';
import { ApplicationSystems } from '../../../../helpers/constants/application-systems.constants';
import { BrowserConstants } from '../../../../helpers/constants/browser.constants';
import { ApplicationInfo } from '../../../../interfaces/support/applications/applicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-loans-applications',
    templateUrl: 'loans-applications.component.html',
    styleUrl: './loans-applications.component.scss',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    host: { class: 'main__content' },
})
export class LoanApplicationsComponent implements AfterViewInit {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';
    public selectedApplication: ApplicationInfo | undefined;
    public applications: ApplicationInfo[] = [];
    BrowserConstants = BrowserConstants;
    ApplicationSystems = ApplicationSystems;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location,
        private translocoService: TranslocoService
    ) {
        this.initApplications();

        this.translocoService.langChanges$.subscribe(() => {
            this.initApplications();
        });
    }

    ngAfterViewInit(): void {
        if (this.dialog) {
            // Timeout is necessary to fix console error ExpressionChangedAfterItHasBeenCheckedError
            window.setTimeout(() => {
                this.checkUrl();
            });
        }
    }

    /**
     * Opens the loan application dialog for the selected application.
     * Retrieves a token and handles redirection based on the application type.
     * @param application The loan application to open
     */
    openApplication(application: ApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => this.location.go('/lanaumsoknir/' + pathSuffix);

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        updateUrlPath(application.identifier);

        if (application.system === ApplicationSystems.Coredata) {
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
                .subscribe((token) => {
                    this.url = `${environment.coredataPortalUrl}?token=${token}`;
                    this.redirectUrl = application.url;
                    application.isLoading = false;
                    openDialogAndSubscribeClose();
                });
        } else if (application.system === ApplicationSystems.Libra) {
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.LIBRA)
                .subscribe((token) => {
                    if (token) {
                        this.authService.authenticateLibraLoan(token).then(() => {
                            this.url = environment.libraPortalUrl + '/applications/overview';
                            application.isLoading = false;
                            openDialogAndSubscribeClose();
                        });
                    } else {
                        application.isLoading = false;
                    }
                });
        }
    }

    /**
     * Checks the current route for specific application parameters and, if present,
     * opens the corresponding application.
     */
    checkUrl() {
        const first = this.route.snapshot.paramMap.get('identifier1');
        const second = this.route.snapshot.paramMap.get('identifier2');
        if (!first) {
            return;
        }

        let identifier = first;
        if (second) {
            identifier += `/${second}`;
        }

        const application = this.applications.find((x) => x.identifier == identifier);
        this.openApplication(application as ApplicationInfo);
    }

    /**
     * Initializes the available loan applications with their respective details.
     */
    initApplications() {
        this.applications = [
            {
                titleTranslation: 'mpaLoanApplicationTitle',
                descriptionTranslation: 'mpaLoanApplicationDescription',
                url: `${environment.libraPortalUrl}/applications/overview`,
                identifier: 'nytt-lan',
                system: ApplicationSystems.Libra,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaLoanDefermentTitle',
                descriptionTranslation: 'mpaLoanDefermentDescription',
                url: `${environment.coredataPortalUrl}/28/forms/56/`,
                identifier: '28/56',
                system: ApplicationSystems.Coredata,
                isLoading: false,
            },
        ];
    }

    checkBrowser(): string {
        return checkBrowser();
    }

    getPageSubtitle = () => {
        if (this.translocoService.getActiveLang() === 'en') {
            return 'mpaLoanApplicationsSubtitle';
        }
        return null;
    };
}
