import { NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { LSRPaginationComponent } from '@lsr/ui-components/lsr-pagination';
import { TableData } from '../../lsr-table-interfaces';
import { isString } from '../../lsr-table.utils';

@Component({
    selector: 'lsr-table-expandable-inner',
    standalone: true,
    imports: [LSRPaginationComponent, NgStyle, TranslocoPipe],
    templateUrl: './lsr-table-expandable-inner.component.html',
    styleUrl: './lsr-table-expandable-inner.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: { class: 'table__expanded-table-row' },
})
export class LSRTableExpandableInnerComponent implements OnInit, AfterViewInit {
    private _data?: TableData;
    @Input()
    set data(value: TableData | undefined) {
        this._data = value;
        this.setPage(this.currentPage);
    }
    get data() {
        return this._data;
    }
    @Input() maxRows: number = 10;
    @Input() parentTableColumns?: number;
    currentPage: number = 1;
    totalPages: number = 1;
    startIndex: number = 0;
    endIndex: number = this.maxRows;
    parentTableContainer?: HTMLElement;

    maxTableWidth: string = '2000px';
    tableLeft: string = '0px';

    // Declare util function for use in component template
    isString = isString;

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.setPage(1);
    }

    ngAfterViewInit() {
        // get parent container to handle widths
        const currentDomEl = this.elRef.nativeElement;
        const parentContainer = currentDomEl.closest('.table__table-container') as HTMLElement;
        if (parentContainer) {
            this.parentTableContainer = parentContainer;
            this.handleInnerTableWidthAndPosition();
            window.addEventListener('resize', this.handleInnerTableWidthAndPosition.bind(this));
            parentContainer.addEventListener('scroll', this.handleInnerTableWidthAndPosition.bind(this));
        }
    }

    setPage(page: number) {
        this.currentPage = page;

        if (this.data) {
            this.totalPages = this.data.rows.length / this.maxRows;
            this.startIndex = (this.currentPage - 1) * this.maxRows;
            this.endIndex = this.startIndex + this.maxRows;
        }
    }

    handleInnerTableWidthAndPosition() {
        const tableWidth = this.parentTableContainer?.offsetWidth || 0;
        const tableScrollWidth = this.parentTableContainer?.scrollWidth || 0;
        const tableScrollLeft = this.parentTableContainer?.scrollLeft || 0;
        const maxScrollLeft = tableScrollWidth - tableWidth;
        const scrollLeftClamped = Math.min(Math.max(0, tableScrollLeft), maxScrollLeft);

        if (tableWidth) {
            this.maxTableWidth = `${tableWidth - 62}px`;
            this.tableLeft = `${scrollLeftClamped}px`;
        }

        this.cdr.detectChanges();
    }
}
