import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { UserService } from '../../../services/user/user.service';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';

@Component({
    selector: 'app-confirmation-email',
    standalone: true,
    imports: [LSRTitleComponent, LSRIconComponent, LSRButtonComponent, TranslocoPipe, LSRLoaderComponent],
    templateUrl: 'email-confirmation.component.html',
    styleUrl: './email-confirmation.component.scss',
    host: { class: 'main__content centered' },
})
export class EmailConfirmationComponent implements OnInit {
    success?: boolean;
    loading: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        const token = this.route.snapshot.paramMap.get('token');
        if (!token) {
            this.success = false;
            this.loading = false;
            return;
        }

        this.userService.confirmEmail(token).subscribe({
            next: (res) => {
                this.success = res;
                this.loading = false;
            },
        });
    }
}
